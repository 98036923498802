import { Card } from "react-bootstrap"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import React from "react"


import "./index.scss";


interface Props {
    className: string,
    bigText: string,
    smallText?: string,
    loading?: boolean
}

export const NumberCard: React.FC<Props> = (props) => {


    return <Card className="card">
        {props.loading ? <SkeletonTheme >
            <Skeleton height={125} />
        </SkeletonTheme>
            :
            <Card.Body className={props.className}>
                <div className="cardValue">
                    {
                        // if bigText has many values then it's duration
                        props.bigText.split(" ").length > 1
                            ? props.bigText.split(" ").map((item, index) => {
                                //if even index then it's a value
                                if (index % 2 === 0) {
                                    return <Card.Text className="card-number" key={index}>{item}</Card.Text>

                                } else {
                                    return <Card.Text className="card-units" key={index} style={{ whiteSpace: `pre` }}> {item}  </Card.Text>
                                }
                            })
                            : <Card.Text className="card-number">{props.bigText} </Card.Text>}
                </div>
                <Card.Text className="cardTopic">{props.smallText}</Card.Text>
            </Card.Body>}

    </Card>

}