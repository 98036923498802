import moment from 'moment'
import humanizeDuration from "humanize-duration"

const ourDurationHumanizer = humanizeDuration.humanizer({
    largest: 2, round: true, serialComma: false, delimiter: " "
})

const cleanTime = (duration: string) => {
    let split = duration.split(" ")
    let cleaned = ""
    for (let i = 0; i < split.length; i++) {
        let d = split[i];
        if (d === "second") d = "sec"
        if (d === "seconds") d = "secs"
        if (d === "minute") d = "min"
        if (d === "minutes") d = "mins"
        if (d === "hour") d = "hr"
        if (d === "hours") d = "hrs"
        if (d === "week") d = "wk"
        if (d === "weeks") d = "wks"
        if (d === "month") d = "mo"
        if (d === "months") d = "mos"
        if (d === "year") d = "yr"
        if (d === "years") d = "yrs"
        cleaned += d + " "
    }
    return cleaned
}

export const durationSeconds = (d: number): string => {

    return cleanTime(ourDurationHumanizer(Number(moment.duration(d, 'seconds'))))

}