import { PageProps, AppState, ReduxAction, ActionPayloads } from "@declarations";
import { DashboardView } from "..";
import React, { Dispatch, useEffect } from "react";
import { makeAction, Actions } from "../../../Store/actions";
import { connect } from "react-redux";
import { Row, Col, Breadcrumb, InputGroup, FormControl, ListGroup, Navbar } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { ItemRow } from "../../../Widgets/ItemRow";

import "./index.css"

type RouteParams = { brand: string }
interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {

}
const BrandView: React.FC<Props> = (props) => {
    let { brand } = useParams<RouteParams>();

    useEffect(()=> {
        if (!brand || !props.appBrandsList) return;
        let brandInView = props.appBrandsList.filter(item => item.name === brand)[0]
        props.setAppBrandInView(brandInView)
    }, [props, brand])


    const [channelSearch, setChannelSearch] = React.useState("")
    const [filteredChannels, setFilteredChannels] = React.useState<AppState.Channel[]>([]);

    //can just check if props type is brand or embed to decide which to update
    function channelSearchChange(e: React.FormEvent<HTMLInputElement>) {
        const searchValue = e.currentTarget.value;
        setChannelSearch(searchValue.toLowerCase())
    }

    React.useEffect(() => {
        if (!props.appBrandInView) return;
        setFilteredChannels(props.appBrandInView.channels.filter(channel => channel.name.match(new RegExp(channelSearch, "i"))));
    }, [props.appBrandInView, channelSearch])


    React.useEffect(() => {
        if (!brand) return;
       props.getAppExperienceList()
    }, [brand]);

    function updateData(timeRange: ActionPayloads.TimeRange) {
        const decodedBrand = decodeURIComponent(brand);

        props.getAppOverviewData(timeRange, decodedBrand);
        props.getAppActivityData(timeRange, decodedBrand);

    }
    return <Row>
        <Col xl={2}>

            <Navbar collapseOnSelect expand="xl" className="flex-column" >
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="navbar-toggle" />
                <Navbar.Collapse id="basic-navbar-nav">

                    <div className="sidebar">
                        <Breadcrumb style={{ backgroundColor: "transparent" }}>
                            <ol className="breadcrumb-list">
                                <li key="home"><Link to={{ pathname: `/app/` }} className="breadcrumb-item">All brands</Link></li>
                                <li key="brand"><Link to={{ pathname: `/app/brand/${brand}` }} className="breadcrumb-item">{brand}</Link></li>
                            </ol>
                        </Breadcrumb>


                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder="Search..."
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                value={channelSearch}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => { channelSearchChange(e) }}
                            />
                        </InputGroup>


                        <ListGroup variant="flush">
                            {
                                filteredChannels.length
                                    ? filteredChannels.map((c, i) => (<ListGroup.Item key={i}>
                                        <ItemRow name={c.name} pathname={`/app/channel/${brand}/${encodeURIComponent(c.name)}`} />
                                    </ListGroup.Item>))
                                    : <ListGroup.Item>No embeds match that search.</ListGroup.Item>
                            }
                        </ListGroup>

                    </div>

                </Navbar.Collapse>
            </Navbar>

        </Col>
        <Col xl={10}> 
            <DashboardView breadcrumbs={[brand]} onRequestUpdate={updateData} view={"brand"} count={props.appBrandInView?.channels?.length} />
        </Col>
    </Row>
}
const mapStateToProps = (state: AppState) => {
    const { appBrandsList, appBrandInView } = state;

    return {
        appBrandsList, appBrandInView
    };
}
const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => {

    return {
        setAppBrandInView: (brand: AppState.AppBrand) => dispatch(makeAction(Actions.SET_CURRENT_APP_BRAND, brand)),
        getAppOverviewData: (timeR: ActionPayloads.TimeRange = {}, brand: string) => dispatch(
            makeAction(Actions.GET_APP_OVERVIEW, { timeRange: timeR, scope: { brand } })),
        getAppActivityData: (timeR: ActionPayloads.TimeRange = {}, brand: string) => dispatch(
            makeAction(Actions.GET_APP_ACTIVITY, { timeRange: timeR, verb: "Experienced", scope: { brand } })),
        getAppExperienceList: () => dispatch(makeAction(Actions.GET_EXPERIENCE_LIST)),

    }
}
export const BrandDashboardHOC = connect(mapStateToProps, mapDispatchToProps)(BrandView)
