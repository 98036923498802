import React from 'react';
import logo from './logo.svg';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { SessionCheck } from './Widgets/SessionCheck';
// import { XYPlot, LineSeries, XAxis, YAxis, HorizontalGridLines, DiscreteColorLegend, AreaSeries, RadialChart, Hint } from 'react-vis';
import { Container, Spinner } from "react-bootstrap"
import { NavBar } from './Widgets/NavBar';
import { GlobalDashboardHOC as AppGlobalDashboard } from './Pages/App/GlobalDashboardHOC';
import { BrandDashboardHOC as AppBrandDashboard } from './Pages/App/BrandDashboardHOC';
import { ChannelDashboardHOC as AppChannelDashboard } from './Pages/App/ChannelDashboardHOC';
import { ExperienceDashboardHOC as AppExperienceDashboard } from './Pages/App/ExperienceDashboardHOC';
import { GlobalDashboardHOC } from './Pages/Web/GlobalDashboardHOC';
import { BrandDashboardHOC } from './Pages/Web/BrandDashboardHOC';
import { EmbedDashboardHOC } from './Pages/Web/EmbedDashboardHOC';
import { Login } from './Pages/Login'
import { PageNotFound } from './Pages/PageNotFound'
import { SelectDashboard } from './Pages/SelectDashboard';
import { Session } from '@declarations';


require('./App.css');
require('bootstrap/dist/css/bootstrap.min.css');
require('react-vis/dist/style.css');

function App() {

  return (
    <div className="App">

      <Router>
        <SessionCheck showIf={"Auth"}>
          <>
            <NavBar />
            <Container fluid className="view">
              {/* routing */}
              <Switch>

                <Route exact path="/">
                  <SelectDashboard />
                </Route>

                {/* Web dashboard */}
                <Route exact path="/web">
                  <SessionCheck showIf="HasAccess" else={(session: Session) => {
                    return <Redirect to={"/web/brand/" + (session as Session.AuthSession).user.brandName} />
                  }}>
                    <GlobalDashboardHOC />
                  </SessionCheck>
                </Route>
                <Route exact path="/web/brand/:brand">
                  <SessionCheck showIf="HasAccess" else={<PageNotFound />}>
                    <BrandDashboardHOC />
                  </SessionCheck>
                </Route>
                <Route exact path="/web/brand/:brand/embed/:embed">
                  <SessionCheck showIf="HasAccess" else={<PageNotFound />}>
                    <EmbedDashboardHOC />
                  </SessionCheck>
                </Route>

                {/* App dashboard */}
                <Route exact path="/app">
                  <SessionCheck showIf="HasAccess" else={(session: Session) => {
                    return <Redirect to={"/app/brand/" + (session as Session.AuthSession).user.brandName} />
                  }}>
                    <AppGlobalDashboard />
                  </SessionCheck>
                </Route>
                <Route exact path="/app/brand/:brand">
                  <SessionCheck showIf="HasAccess" else={<PageNotFound />}>
                    <AppBrandDashboard />
                  </SessionCheck>
                </Route>

                <Route exact path="/app/channel/:brand/:channel">
                  <SessionCheck showIf="HasAccess" else={<PageNotFound />}>
                    <AppChannelDashboard />
                  </SessionCheck>
                </Route>
                <Route exact path="/app/experience/:brand/:channel/:experience">
                  <SessionCheck showIf="HasAccess" else={<PageNotFound />}>
                    <AppExperienceDashboard />
                  </SessionCheck>
                </Route>

                <Route path="*">
                  <PageNotFound />
                </Route>

              </Switch>

            </Container>

            <footer className="page-footer font-small unique-color-dark pt-4">
              <div className="footer-copyright text-center py-3">© 2020 Copyright
              <a href="https://arm.nextechar.com/"> NexTech AR Solutions Inc.</a>
              </div>
            </footer>
          </>
        </SessionCheck>
        <SessionCheck showIf="Guest">
          <Login />

        </SessionCheck>

        <SessionCheck showIf="Verifying">
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "50vh" }}>
            <Spinner animation="border" />
          </div>
        </SessionCheck>
      </Router>

    </div >
  );
}

export default App;

