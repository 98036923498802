import { PageProps, AppState, ReduxAction, ActionPayloads } from "@declarations";
import { DashboardView } from "..";
import React, { Dispatch } from "react";
import { makeAction, Actions } from "../../../Store/actions";
import { connect } from "react-redux";
import { Row, Col, Breadcrumb, Navbar } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import "./index.css"

type RouteParams = { embed: string, brand: string }
interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {

}
const EmbedView: React.FC<Props> = (props) => {


    let { embed, brand } = useParams<RouteParams>();

    React.useEffect(() => {
        if (!embed) return;
        const decodedEmbed = decodeURIComponent(embed);
        // props.getOverviewData(decodedEmbed);
        // props.getPeriodicData(decodedEmbed);
        props.getEmbedDetails(decodedEmbed)
    }, [embed]);

    function updateData(timeRange: ActionPayloads.TimeRange) {
        const decodedEmbed = decodeURIComponent(embed);
        props.getOverviewData(decodedEmbed, timeRange);
        props.getPeriodicData(decodedEmbed, timeRange);
    }
    return <Row>
        <Col xl={2}>

            <Navbar collapseOnSelect  expand="xl" className="flex-column" style={{overflowWrap: `anywhere`, padding: `0rem`}} >
            <Navbar.Toggle aria-controls="basic-navbar-nav" className="navbar-toggle"/>
            <Navbar.Collapse id="basic-navbar-nav">
            <div>
                <Breadcrumb style={{ backgroundColor: "transparent" }}>
                    <ol className="breadcrumb-list">
                        <li key="home"> <Link to={{ pathname: `/web/` }}>All brands</Link> </li>
                        <li key="brand"> <Link to={{ pathname: `/web/brand/${brand}` }}>{props.embedInView.brand.name}</Link> </li>
                        <li key="embed"> <Link to={{ pathname: `/web/brand/${brand}/embed/${embed}` }}>{props.embedInView.title}</Link> </li>
                    </ol>
                </Breadcrumb>

            </div>

            </Navbar.Collapse> 
            </Navbar>
        </Col>
        <Col xl={10}>
            <DashboardView breadcrumbs={[brand,embed]} onRequestUpdate={updateData} view={"embed"} context={props.embedInView}/>

        </Col>
    </Row>
}

const mapStateToProps = (state: AppState) => {
    const { embedInView } = state;
    // console.log("props.embedsListInView",state)

    return {
        embedInView,
    };
}
const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => {
    return {
        getOverviewData: (id: string, timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_OVERVIEW_DATA, { timeRange, scopeFilter: { scope: "embed", id } })
        ),
        getPeriodicData: (id: string, timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_PERIODIC_DATA, { timeRange, scopeFilter: { scope: "embed", id } })
        ),
        getEmbedDetails: (embed: string) => {
            dispatch(makeAction(Actions.GET_EMBED_DETAILS, { embed }))
        }
    }
}
export const EmbedDashboardHOC = connect(mapStateToProps, mapDispatchToProps)(EmbedView)
