import { PageProps, AppState, ReduxAction, ActionPayloads } from "@declarations";
import { DashboardView } from "..";
import React, { Dispatch } from "react";
import { makeAction, Actions } from "../../../Store/actions";
import { connect } from "react-redux";
import { Row, Col, Breadcrumb, InputGroup, FormControl, ListGroup, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ItemRow } from "../../../Widgets/ItemRow";

import './index.css'

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {

}
const GlobalView: React.FC<Props> = (props) => {

    const [brandSearch, setBrandSearch] = React.useState("")
    const [filteredBrands, setFilteredBrands] = React.useState<AppState.Brand[]>([]);
    // const [expanded, setExpanded] = React.useState(false)


    //can just check if props type is brand or embed to decide which to update
    function brandSearchChange(e: React.FormEvent<HTMLInputElement>) {
        const searchValue = e.currentTarget.value;
        setBrandSearch(searchValue.toLowerCase())
        // let expand: boolean = true
        // if (searchValue === "") {
        //     expand = false
        // }
        // setExpanded(expand)
    }

    React.useEffect(() => {
        setFilteredBrands(props.brandsList.filter(brand => brand.name.match(new RegExp(brandSearch, "i"))));
    }, [props.brandsList, brandSearch])

    React.useEffect(() => {

        props.getBrands();
    }, []);

    function updateData(timeRange: ActionPayloads.TimeRange) {

        props.getOverviewData(timeRange);
        props.getPeriodicData(timeRange);
    }
    return <Row>

        <Col xl={2}>

            <Navbar collapseOnSelect  expand="xl" className="flex-column" >
            <Navbar.Toggle aria-controls="basic-navbar-nav" className="navbar-toggle"/>
            <Navbar.Collapse id="basic-navbar-nav">

                <div>

                    <Breadcrumb style={{ backgroundColor: "transparent" }}>
                        <Link to={{ pathname: `/web/` }}>All brands</Link>
                    </Breadcrumb>

                    <InputGroup className="mb-3">
                        <FormControl
                            placeholder="Search..."
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                            value={brandSearch}
                            onChange={(e: React.FormEvent<HTMLInputElement>) => { brandSearchChange(e) }}
                        />
                    </InputGroup>

                    <ListGroup variant="flush">
                        {
                            filteredBrands.length
                                ? filteredBrands.map((b,index) => (<ListGroup.Item key={index}><ItemRow name={b.name} pathname={`/web/brand/${b.name}`} /></ListGroup.Item>))
                                : <ListGroup.Item>No brands match that search.</ListGroup.Item>
                        }
                    </ListGroup>
    
                    <br/>

                </div>

            </Navbar.Collapse> 
            </Navbar>

        </Col>
        <Col xl={10}>
            <DashboardView breadcrumbs={["All brands"]} onRequestUpdate={updateData} view={"global"} count={props.brandsList.length} />
        </Col>

    </Row>
}

const mapStateToProps = (state: AppState) => {
    const { brandsList } = state;
    return {
        brandsList,
    };
}

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => {
    return {
        getOverviewData: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_OVERVIEW_DATA, { timeRange, scopeFilter: { scope: "global" } })
        ),
        getPeriodicData: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_PERIODIC_DATA, { timeRange, scopeFilter: { scope: "global" } })
        ),
        getBrands: () => dispatch(makeAction(Actions.GET_BRANDS)),
        getEmbeds: (id: string) => dispatch(makeAction(Actions.GET_EMBEDS, { brand: id })),
    }
}
export const GlobalDashboardHOC = connect(mapStateToProps, mapDispatchToProps)(GlobalView)
