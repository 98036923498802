import React from "react"

import "./index.css";

interface Props {
    items: Array<{ title: string, value: number, color?: string }>,
}
export const DonutLegend: React.FC<Props> = (props) => {

    return <div className="new-legend">
                {props.items
                    .sort((a,b)=> (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))
                    .map((item,index) => (
                    <div className="each-legend" key={index}>
                        <div style={{backgroundColor: item.color }} className="label"></div>
                        <p className="text">{item.title}</p>
                        <p className="text">{item.value}</p>
                    </div>
                ))}
            </div>
}