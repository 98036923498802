import { put, call, all, take } from 'redux-saga/effects'
import { ReduxAction, AppState, ActionPayloads, Session } from '@declarations';
import { getGlobalOverviewQry, getGlobalOpensByDay, getUriOpensByDay, getUriOverview, getCartAddsByPeriod } from "../Utils/webQueries"
import { XapiKeys, AppDataParams, AppActivityParams, WithScope } from '../Values/xapiKeys';
import { xApi } from '../Utils/RemoteCalls';
import { makeAction, Actions } from '../Store/actions';
import { InitState } from '../Store/Reducers/index';
import { store } from '../Store';

import { getAppOverview, getAppActivity } from '../Utils/appQueries'
function parseOverviewData(data: any[]) {
    let dwellCount = 0;
    const emptyDashData = JSON.parse(JSON.stringify(InitState.dashboardData)) as AppState.DashboardData;

    function getDeviceType(value: string, lookIn: "Definitions" | "Extensions") {
        if (lookIn === "Definitions") {
            const knownDevices: Array<keyof typeof XapiKeys.DeviceMapping.toDefinitions> = ["Mobile", "Desktop", "AR"]
            const device = knownDevices.find(device => {
                return XapiKeys.DeviceMapping.toDefinitions[device].some((item) => {
                    const defId = item as keyof typeof XapiKeys.Definitions
                    return (XapiKeys.Definitions[defId] === value)
                })

            });
            return device;
        } else {
            const knownDevices: Array<keyof typeof XapiKeys.DeviceMapping.toExtType> = ["Mobile", "Desktop"]
            const device = knownDevices.find(device => {
                return XapiKeys.DeviceMapping.toExtType[device].some((item) => {
                    return (item === value)
                })
            });
            return device;

        }
    }

    const { periodChartData, ...placeholder } = { ...emptyDashData };
    // const placeholder = {
    //     dwellTime: 0,
    //     dwellAvg: 0,
    // }
    const parsedData = data.reduce<typeof placeholder>((res, item: any) => {
        if (item._id.verb === XapiKeys.Verbs.complete) {
            res.dwellTime = (res.dwellTime || 0) + item.totalDuration;
            dwellCount = dwellCount + item.count;
        }
        if (item._id.verb === XapiKeys.Verbs.open && item._id.defType) {
            const device = getDeviceType(item._id.defType, "Definitions");
            if (!device) return res;
            res.totalOpens = res.totalOpens || { ...emptyDashData.totalOpens };
            res.totalOpens.total += item.count
            res.totalOpens.breakdown[device] = (res.totalOpens.breakdown[device] || 0) + item.count;
        }
        if (item._id.verb === XapiKeys.Verbs.saved && item._id.defType) {
            const device = getDeviceType(item._id.defType, "Definitions");
            if (!device) return res;
            res.totalSaves = res.totalSaves || { ...emptyDashData.totalSaves };
            res.totalSaves.total += item.count
            res.totalSaves.breakdown[device] = (res.totalSaves.breakdown[device] || 0) + item.count;
        }
        if (item._id.verb === XapiKeys.Verbs.addToCart && item._id.defType) {
            const device = getDeviceType(item._id.defType, "Definitions");
            if (!device) return res;
            res.totalAddToCarts = res.totalAddToCarts || { ...emptyDashData.totalAddToCarts };
            res.totalAddToCarts.total += item.count
            res.totalAddToCarts.breakdown[device] = (res.totalAddToCarts.breakdown[device] || 0) + item.count;
        }
        if (item._id.verb === XapiKeys.Verbs.view && item._id.viewDevice) {
            const device = getDeviceType(item._id.viewDevice, "Extensions");
            if (!device) return res;
            res.totalViews = res.totalViews || { ...emptyDashData.totalViews };
            res.totalViews.total += item.count;
            res.totalViews.breakdown[device] = (res.totalViews.breakdown[device] || 0) + item.count;

        }
        return res;
    }, placeholder)
    // console.log("parsedData", parsedData, emptyDashData);
    parsedData.dwellAvg = (parsedData.dwellTime || 0) / (dwellCount || 1);
    parsedData.estDwellTime = parsedData.dwellAvg * parsedData.totalOpens.total;
    return parsedData;
}
export function* updateOverviewData({ payload }: ReduxAction<{ timeRange?: ActionPayloads.TimeRange, scopeFilter?: ActionPayloads.ScopeFilter }>) {
    yield put(makeAction(Actions.SET_OVERVIEW_WAIT_STATE, true));

    const fallbacks: Required<typeof payload> = {
        timeRange: {}, scopeFilter: { scope: "global", }
    }
    const payloadWithFallbacks: typeof fallbacks = { ...fallbacks, ...(payload || fallbacks) };
    const { timeRange, scopeFilter: { scope, id } } = payloadWithFallbacks;


    let query: Record<string, any>[] = [];
    if (scope === "global") query = getGlobalOverviewQry(timeRange);
    else if (scope === "brand") {
        // id is brand name
        if (!id) throw new Error("Calls with scope " + scope + " should include ids (brand name)")
        yield put(makeAction(Actions.GET_EMBEDS, { brand: id }));
        yield take(Actions.SET_EMBEDS_LIST);
        let embeds = store.getState().embedsListInView;

        query = getUriOverview(timeRange, embeds.map(embed => embed.pageUrl));

    } else if (scope === "embed") {
        // id is pageUrl
        if (!id) throw new Error("Calls with scope " + scope + " should include ids (pageUrl)")
        query = getUriOverview(timeRange, [id]);

    }

    const data: any[] = yield xApi(query)
    const parsedData = parseOverviewData(data)
    const dataToDispatch: AppState.DashboardData = {
        ...InitState.dashboardData, ...parsedData
    }
    // console.log(">>----", makeAction(Actions.SET_DASHBOARD_OVERVIEW_DATA, dataToDispatch))
    yield put(makeAction(Actions.SET_DASHBOARD_OVERVIEW_DATA, dataToDispatch))
    yield put(makeAction(Actions.SET_OVERVIEW_WAIT_STATE, false));

}

export function* updatePeriodicData({ payload }: ReduxAction<{ timeRange?: ActionPayloads.TimeRange, scopeFilter?: ActionPayloads.ScopeFilter }>) {
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));
    const fallbacks: Required<typeof payload> = {
        timeRange: {}, scopeFilter: { scope: "global", }
    }
    const payloadWithFallbacks: typeof fallbacks = { ...fallbacks, ...(payload || fallbacks) };
    const { timeRange, scopeFilter: { scope, id } } = payloadWithFallbacks;

    let opensQuery: Record<string, any>[] = [], cartAddsQuery: Record<string, any>[] = [];
    if (scope === "global") {
        opensQuery = getGlobalOpensByDay(timeRange);
        cartAddsQuery = getCartAddsByPeriod(timeRange, "global", []);
    }
    else if (scope === "brand") {
        //assume id is brand name
        //find uri's from name-mapping
        if (!id) throw new Error("Calls with scope " + scope + " should include ids (brand name)")
        yield put(makeAction(Actions.GET_EMBEDS, { brand: id }))
        yield take(Actions.SET_EMBEDS_LIST)
        let embeds = store.getState().embedsListInView;

        opensQuery = getUriOpensByDay(timeRange, scope, embeds.map(embed => embed.pageUrl));
        cartAddsQuery = getCartAddsByPeriod(timeRange, scope, embeds.map(embed => embed.pageUrl));
    } else if (scope === "embed") {
        if (!id) throw new Error("Calls with scope " + scope + " should include ids (pageUrls)")
        opensQuery = getUriOpensByDay(timeRange, scope, [id]);
        cartAddsQuery = getCartAddsByPeriod(timeRange, scope, [id]);
    }
    // console.log("periodicData", opensQuery);
    const [opensData, cartsData]: any[][] = yield all([xApi(opensQuery), xApi(cartAddsQuery)]);
    const opensDataToDispatch = opensData.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.openCount,
            instance: new Date(item._id)
        }
    });
    const cartsDataToDispatch = cartsData.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.openCount,
            instance: new Date(item._id)
        }
    })
    yield put(makeAction(Actions.SET_DASHBOARD_PERIODIC_DATA, { opensData: opensDataToDispatch, cartsData: cartsDataToDispatch }));
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));

}

function isAuthorized(scope: WithScope<{}>["scope"] | undefined) {
    const expId = scope?.experienceId
    const session = store.getState().session as Session.AuthSession;
    const brands = store.getState().appBrandsList;
    const brand = session.user.brandName;
    if (session.user.admin) return true;
    if (scope) {
        return scope.brand === brand
    }
    if (!expId) return false;
    return !!brands.find(
        brandItem => brandItem.name === brand && brandItem.channels.find(
            channel => channel.experiences.find(exp => exp.id === expId)
        )
    )

}

function* assureBrandlist() {
    const brands = store.getState().appBrandsList;
    if (brands && brands.length) return;
    yield put(makeAction(Actions.GET_EXPERIENCE_LIST))
    yield take(Actions.SET_APP_BRANDS_LIST)
}

function* resolveAppScope(payload: WithScope<AppDataParams>) {
    let expIds: string[] | undefined;
    if (payload.scope) {
        const { brand, channel } = payload.scope;
        const brands = store.getState().appBrandsList;
        const brandObj = brands.find(item => item.name === brand);
        if (channel) {
            expIds = brandObj?.channels.find(item => item.name === channel)?.experiences.map(exp => exp.id);
        } else expIds = brandObj?.channels.reduce((arr, channel) => {
            channel.experiences.forEach(exp => arr.push(exp.id));
            return arr;
        }, [] as string[])

        if (expIds && payload.scope?.experienceId) {
            expIds = expIds.filter(id => id === payload.scope?.experienceId)
        }
    }
    const { breakdownBy, timeRange, verb } = payload;
    return { breakdownBy, timeRange, experienceIds: expIds?.length ? expIds : null, verb };
}

export function* updateAppOverview({ payload }: ReduxAction<WithScope<AppDataParams>>) {
    if (!payload) throw new Error("Params cannot be empty");
    yield put(makeAction(Actions.SET_OVERVIEW_WAIT_STATE, true));
    yield assureBrandlist();
    if (!isAuthorized(payload.scope)) {
        alert("not authorized");
        yield put(makeAction(Actions.SET_NOT_FOUND, true))
        return;
    }
    const resolvedPayload: AppActivityParams = yield resolveAppScope(payload);
    if (!resolvedPayload.experienceIds && payload.scope) {
        yield put(makeAction(Actions.SET_NOT_FOUND, true))
        return;
    }
    console.log("___", resolvedPayload, payload);
    const query = getAppOverview(resolvedPayload)

    const dataToDispatch: AppState.AppDashboardData["overview"] = yield xApi(query);
    yield put(makeAction(Actions.SET_APP_OVERVIEW, dataToDispatch))
    yield put(makeAction(Actions.SET_OVERVIEW_WAIT_STATE, false));
}

export function* updateAppActivity({ payload }: ReduxAction<WithScope<AppActivityParams>>) {
    if (!payload) throw new Error("Params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));
    yield assureBrandlist();
    if (!isAuthorized(payload.scope)) {
        yield put(makeAction(Actions.SET_NOT_FOUND, true))
        return;
    }
    const resolvedPayload: AppActivityParams = yield resolveAppScope(payload);
    if (!resolvedPayload.experienceIds?.length && payload.scope) {
        yield put(makeAction(Actions.SET_NOT_FOUND, true))
        return;
    }
    const query = getAppActivity(resolvedPayload)

    const data: any[] = yield xApi(query)
    const dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    yield put(makeAction(Actions.SET_APP_ACTIVITY, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}