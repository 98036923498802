import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

export const NavBar: React.FC = (props) => {
    return <Navbar bg="light" expand="lg" fixed="top">
        <Navbar.Brand><Link to="/">NexTechAR Analytics</Link></Navbar.Brand>
        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
        {/* <Navbar.Collapse id="basic-navbar-nav">

            <Nav className="mr-auto">
                <Link to="/" className="nav-link">Home</Link>
                <Link to="/Pricing" className="nav-link">Pricing</Link>
                <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                    <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                </NavDropdown>
            </Nav>
            <Form inline>
                <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                <Button variant="outline-success">Search</Button>
            </Form>
                <Nav.Link >Sign Up</Nav.Link>
                <Nav.Link >Log in</Nav.Link>

        </Navbar.Collapse> */}
    </Navbar>
}