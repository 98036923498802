import { PageProps, AppState, ReduxAction, ActionPayloads } from "@declarations";
import { DashboardView } from "..";
import React, { useEffect, Dispatch } from "react";
import { makeAction, Actions } from "../../../Store/actions";
import { connect } from "react-redux";
import { Row, Col, Breadcrumb, InputGroup, FormControl, ListGroup, Navbar } from "react-bootstrap";
import { ItemRow } from "../../../Widgets/ItemRow";
import { Link, useParams } from "react-router-dom";
import "./index.css"

type RouteParams = { brand: string, channel: string, }
interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {

}
const ChannelView: React.FC<Props> = (props) => {

    let { brand, channel } = useParams<RouteParams>();

    useEffect(()=> {
        let brandInView = props.appBrandsList.filter(item => item.name === brand)[0]
        props.setAppBrandInView(brandInView)
    }, [props, brand])

    useEffect(() => {
        if (props.appBrandInView) {
            props.setChannelInView(props.appBrandInView.channels.filter(c => c.name === channel)[0])
        }
    }, [props, channel])

    const [expSearch, setExpSearch] = React.useState("")
    const [filteredExps, setFilteredExps] = React.useState<AppState.Experience[]>([]);

    //can just check if props type is brand or embed to decide which to update
    function expSearchChange(e: React.FormEvent<HTMLInputElement>) {
        const searchValue = e.currentTarget.value;
        setExpSearch(searchValue.toLowerCase())
    }

    React.useEffect(() => {
        if (!props.channelInView) return;
        setFilteredExps(props.channelInView.experiences.filter(e => e.name.match(new RegExp(expSearch, "i"))));
    }, [props, expSearch])


    useEffect(() => {
        if (!brand) return;
        props.getAppExperienceList()
    }, [brand]);


    function updateData(timeRange: ActionPayloads.TimeRange) {
        const decodedBrand = decodeURIComponent(brand);
        const decodedChannel = decodeURIComponent(channel);
        
        props.getAppOverviewData(timeRange, decodedBrand, decodedChannel);
        props.getAppActivityData(timeRange, decodedBrand, decodedChannel);
    }

    return <Row>
        <Col xl={2}>

            <Navbar collapseOnSelect expand="xl" className="flex-column" style={{ overflowWrap: `anywhere`, padding: `0rem` }} >
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="navbar-toggle" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <div>
                        <Breadcrumb style={{ backgroundColor: "transparent" }}>
                            <ol className="breadcrumb-list">
                                <li key="home"> <Link to={{ pathname: `/app/` }}>All brands</Link> </li>
                                <li key="brand"> <Link to={{ pathname: `/app/brand/${brand}` }}>{brand}</Link> </li>
                                <li key="embed"> <Link to={{ pathname: `/app/channel/${brand}/${channel}` }}>{channel}</Link> </li>
                            </ol>
                        </Breadcrumb>

                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder="Search..."
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                value={expSearch}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => { expSearchChange(e) }}
                            />
                        </InputGroup>


                        <ListGroup variant="flush">
                            {
                                filteredExps.length
                                    ? filteredExps.map((e, i) => (<ListGroup.Item key={i}>
                                        <ItemRow name={e.name} pathname={`/app/experience/${brand}/${channel}/${encodeURIComponent(e.id)}`} />
                                    </ListGroup.Item>))
                                    : <ListGroup.Item>No embeds match that search.</ListGroup.Item>
                            }
                        </ListGroup>

                    </div>

                </Navbar.Collapse>
            </Navbar>
        </Col>
        <Col xl={10}>
            <DashboardView breadcrumbs={[brand, channel]} onRequestUpdate={updateData} view={"channel"} context={props.channelInView} count={props.channelInView?.experiences?.length} />

        </Col>
    </Row>
}

const mapStateToProps = (state: AppState) => {
    const { appBrandsList, appBrandInView, channelInView } = state;

    return {
    appBrandsList, appBrandInView, channelInView
    };
}
const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => {
    return {
        setAppBrandInView: (brand: AppState.AppBrand) => dispatch(makeAction(Actions.SET_CURRENT_APP_BRAND, brand)),
        setChannelInView: (channel: AppState.Channel) => dispatch(makeAction(Actions.SET_CURRENT_CHANNEL, channel)),
        getAppOverviewData: (timeR: ActionPayloads.TimeRange = {}, brand: string, channel: string) => dispatch(
            makeAction(Actions.GET_APP_OVERVIEW, { timeRange: timeR, scope: { brand, channel } })),
        getAppActivityData: (timeR: ActionPayloads.TimeRange = {}, brand: string, channel: string) => dispatch(
            makeAction(Actions.GET_APP_ACTIVITY, { timeRange: timeR, verb: "Experienced", scope: { brand, channel } })),
        getAppExperienceList: () => dispatch(makeAction(Actions.GET_EXPERIENCE_LIST))
    }
}
export const ChannelDashboardHOC = connect(mapStateToProps, mapDispatchToProps)(ChannelView)
