import { ActionPayloads } from "@declarations";
import { XapiKeys, VerbMap, ExtnMap, AppDataParams } from "../Values/xapiKeys";
import { VeracityDate, generateTimeSampling } from "./methods";




const PERIODIC_DATA_SAMPLE_SIZE = 30
const upperDwellCap = 3600; // (1 hour) in seconds cut records above this threshold
// const unknownDwellTime = 60; // (1 minute) in seconds use this for all sessions that didn't end
const lowerDwellCap = 1; // in seconds cut records above this threshold
const BREAKDOWN_LIMIT = 15; // number of breakdown items returned for each group
const PROJECTED_EXT_FIELD = `EXT`;
const EXT_PATH = "statement.object.definition.extensions";
const FACET_SEPERATOR = '-';
// reusable query fragments
const EXTENSIONS_DUPLICATION = [{
    "$addFields": {
        [PROJECTED_EXT_FIELD]: "$statement.object.definition"
    }
},
{
    "$addFields": {
        [PROJECTED_EXT_FIELD]: `$${PROJECTED_EXT_FIELD}.extensions`
    }
}]

const OUTLIER_DWELL_TIMES = {
    $or: [
        {
            [PROJECTED_EXT_FIELD + '.https://aritize*`*nextechar*`*com/secondsfromstart']: {
                $lte: upperDwellCap, "$gte": lowerDwellCap
            }
        },
        { "statement.verb.id": { $ne: XapiKeys.APP.Verbs.ExperienceClosed } }
    ]
};

function facetGenerator() {
    // const unwrapStage = {}
    const limitStages = [
        { $sort: { count: -1 } },
        { $limit: BREAKDOWN_LIMIT }
    ];
    const facets: any = {};
    (Object.keys(XapiKeys.APP.Verbs) as Array<keyof typeof XapiKeys.APP.Verbs>).forEach(verb => {

        facets[verb + FACET_SEPERATOR + 'total'] = [
            { $match: { "_id.verb": XapiKeys.APP.Verbs[verb] } },
            {
                "$group": {
                    _id: "$_id.verb",
                    count: { $sum: "$count" },
                    // dwellTime: { $sum: "$dwellTime" },
                    secondsFromStart: { $sum: "$secondsFromStart" }
                }
            },
            ...limitStages,

        ];

        (Object.keys(XapiKeys.APP.Extensions) as Array<keyof typeof XapiKeys.APP.Extensions>).forEach((extnName) => {
            if (XapiKeys.APP.Extensions[extnName].restrictedTo && (XapiKeys.APP.Extensions[extnName].restrictedTo as string[]).indexOf(verb) === -1) return;
            facets[verb + FACET_SEPERATOR + extnName] = [
                { $match: { "_id.verb": XapiKeys.APP.Verbs[verb] } },
                {
                    "$group": {
                        _id: `$_id.${extnName}`,
                        count: { $sum: "$count" },
                        // dwellTime: { $sum: "$dwellTime" },
                        secondsFromStart: { $sum: "$secondsFromStart" }
                    }
                },
                ...limitStages,
            ]
        });
    })

    return facets;
}


function facetReducer() {
    return Object.keys(XapiKeys.APP.Verbs).reduce((obj, verb) => {

        obj[verb] = Object.keys(XapiKeys.APP.Extensions).reduce((obj, ext) => {
            obj[ext] = '$' + verb + FACET_SEPERATOR + ext;
            return obj;
        }, { total: '$' + verb + FACET_SEPERATOR + 'total' } as any)
        return obj;
    }, {} as any)
}

function facetTrim(verbList: string[], originalFacet: Record<string, any>) {
    const excludedVerbs = (Object.keys(XapiKeys.APP.Verbs) as Array<keyof VerbMap>).filter(key => {
        // exclude matched
        const verb = XapiKeys.APP.Verbs[(key)];
        return verbList.indexOf(verb) === -1
    });

    const newFacet = Object.keys(originalFacet).reduce((obj, facetKey) => {
        const [verbPart] = facetKey.split(FACET_SEPERATOR);
        if (excludedVerbs.indexOf(verbPart as keyof VerbMap) === -1) {
            obj[facetKey] = originalFacet[facetKey]
        }
        return obj;
    }, {} as Record<string, any>);

    return newFacet;
}



const generateNumbersTemplate = () => [
    {
        $match: {
            "statement.verb.id": {
                "$in": Object.values(XapiKeys.APP.Verbs)
            },
            "statement.timestamp": { "$lte": VeracityDate(new Date()) } // to be changed aas needed
        }
    },
    // required for breakdown queries matching extension values
    ...EXTENSIONS_DUPLICATION,
    {
        $match: { ...OUTLIER_DWELL_TIMES }
    }, // required for breakdown queries matching extension values
    {
        $group: {
            _id: {
                verb: "$statement.verb.id",
                ...((Object.keys(XapiKeys.APP.Extensions) as Array<keyof ExtnMap>)
                    .reduce((obj, ext) => ({ ...obj, [ext]: `$${EXT_PATH}.${XapiKeys.APP.Extensions[ext].path}` }), {}))
            },
            count: { $sum: 1 },
            // dwellTime: { $sum: '$' + EXT_PATH + '.https://arecomm*`*nextechar*`*com/extension/durationSeconds' }, // I think this is not used for app reporting
            secondsFromStart: { $sum: '$' + EXT_PATH + '.https://aritize*`*nextechar*`*com/secondsfromstart' }
        }
    },
    {
        $facet: facetGenerator()
    },
    {
        $project: facetReducer()
    },

];


const defaultTimeRange: Required<ActionPayloads.TimeRange> = {
    from: (d => new Date(d.setDate(d.getDate() - 1)))(new Date), // yesterday
    to: new Date()
}

export function getAppOverview(params: AppDataParams) {
    const clone: any = generateNumbersTemplate();
    const [{ $match }, _copy_defenition, _copy_extensions, { $match: $extnMatch }, { $group }, { $facet }, { $project }] = clone;
    if (params.breakdownBy) {
        const breakdownParams = params.breakdownBy;
        if ((Object.keys(params.breakdownBy) as Array<keyof ExtnMap>).every(extn => !!XapiKeys.APP.Extensions[extn].restrictedTo)) {
            const list = (Object.keys(breakdownParams) as Array<keyof ExtnMap>)
                .filter(extn => !!XapiKeys.APP.Extensions[extn].restrictedTo)
                .reduce((list, extn) => {
                    const restrictedTo = XapiKeys.APP.Extensions[extn].restrictedTo;
                    (restrictedTo as Array<keyof VerbMap>).forEach(verb => list.push(XapiKeys.APP.Verbs[verb]))
                    return list;
                }, [] as string[]);
            if (list.length) $match["statement.verb.id"] = {
                "$in": list
            };
        }
        (Object.keys(breakdownParams) as Array<keyof ExtnMap>).forEach(extn => {
            const path = PROJECTED_EXT_FIELD + "." + XapiKeys.APP.Extensions[extn].path;
            const matches = $extnMatch[path] || { $in: [] };
            matches.$in.push(...(breakdownParams[extn] as string[]));
            $extnMatch[path] = matches;
        })

    }
    if (params.verb) {
        $match["statement.verb.id"] = { $in: [XapiKeys.APP.Verbs[params.verb]] };
    }

    if (params.experienceIds) {
        $match["statement.object.id"] = { $in: params.experienceIds.map(id => XapiKeys.APP.EXPERIENCE_ID_PREFIX + id) };

    }

    const $trimmedFacets = facetTrim($match["statement.verb.id"].$in, $facet)
    const { from, to = new Date() } = { ...defaultTimeRange, ...params.timeRange };
    $match["statement.timestamp"] = { $lte: VeracityDate(to), "$gte": VeracityDate(from) };

    return [{ $match }, _copy_defenition, _copy_extensions, { $match: $extnMatch }, { $group }, { $facet: $trimmedFacets }, { $project }]
}

// Activity query

function generateActivityTemplate() {
    return [
        {
            "$match": {},
        },
        // required for breakdown queries matching extension values
        ...EXTENSIONS_DUPLICATION,
        {
            $match: { ...OUTLIER_DWELL_TIMES }
        }, // required for breakdown queries matching extension values
        {
            "$group": {
                "_id": {
                    "$dateToString": {
                        "format": "%Y-%m-%dT00:00:00.000Z",
                        "date": "$statement.timestamp"
                    }
                },
                "count": {
                    "$sum": 1
                }
            }
        },
        {
            "$addFields": {
                "isoTime": {
                    "$toDate": "$_id"
                }
            }
        },
        {
            "$sort": {
                "isoTime": 1
            }
        },
        // ...AUTO_BUCKET_STAGES
    ]
}

export function getAppActivity(params: AppDataParams) {
    const clone: any = generateActivityTemplate();
    const [{ $match }, _copy_defenition, _copy_extensions, { $match: $extnMatch }, { $group }, ...rest] = clone;
    if (params.verb) {
        $match["statement.verb.id"] = { $in: [XapiKeys.APP.Verbs[params.verb]] };
    } else throw new Error("Verb required")

    if (params.experienceIds) {
        $match["statement.object.id"] = { $in: params.experienceIds.map(id => XapiKeys.APP.EXPERIENCE_ID_PREFIX + id) };

    }
    const { from, to = new Date() } = { ...defaultTimeRange, ...params.timeRange };
    $match["statement.timestamp"] = { $lte: VeracityDate(to), "$gte": VeracityDate(from) };

    $group._id.$dateToString.format = generateTimeSampling(from, to);


    return [{ $match }, _copy_defenition, _copy_extensions,  { $match: $extnMatch }, { $group }, ...rest];
}

console.log("NumbersTemplate", getAppOverview({ breakdownBy: { platform: ["android"] }, verb: "Loggedin" }))
console.log("ActivityTemplates", getAppActivity({ breakdownBy: { platform: ["android"] }, verb: "Loggedin" }))