import { ActionPayloads, AppState } from "@declarations";

export interface DevicesSkeleton<T = string> { "Mobile": T[], "Desktop": T[], "AR": T[] };

export type ExtnMap = typeof XapiKeys.APP.Extensions;
export type VerbMap = typeof XapiKeys.APP.Verbs;
// type g=keyof ExtnMap
export type ExtnLike<P> = Partial<Record<keyof ExtnMap, P>>
export type AppDataParams = {
    timeRange?: ActionPayloads.TimeRange,
    breakdownBy?: ExtnLike<string[]>,
    verb?: keyof VerbMap,
    experienceIds?: string[],
}
export interface AppActivityParams extends AppDataParams {
    verb: keyof VerbMap
}
export type WithScope<P> = Omit<P, "experienceIds"> & { scope?: { brand: string, channel?: string, experienceId?: string } }

const Verbs = {
    complete: "http://activitystrea.ms/schema/1.0/complete",
    open: "http://activitystrea.ms/schema/1.0/start",
    view: "http://id.tincanapi.com/verb/viewed",
    addToCart: "https://arecomm.nextechar.com/xAPI/verbs/addedtocart",
    saved: "http://activitystrea.ms/schema/1.0/save"
}

const Definitions = {
    "3DView": "https://arecomm.nextechar.com/3DView",
    "ARView": "https://arecomm.nextechar.com/ARView",
    "Mobile3DView": "https://arecomm.nextechar.com/Mobile3DView",
    "TryOn": "https://arecomm.nextechar.com/TryOn",
    "Button": "https://arecomm.nextechar.com/button",
};

type DeviceMapping = {
    toDefinitions: DevicesSkeleton<keyof typeof Definitions>,
    toExtType: DevicesSkeleton
}
const DeviceMapping: DeviceMapping = {
    toDefinitions: {
        Mobile: ["Mobile3DView"],
        Desktop: ["3DView"],
        AR: ["ARView"]
        //something else
    },
    toExtType: {
        // mapping to value in $statement.object.definition.extensions.https://arecomm*`*nextechar*`*com/extension/type
        // these values are used in views
        Mobile: ["mobile"],
        Desktop: ["desktop"],
        AR: []
    }
}


// App analytics keys
const appVerbs = {
    "CodeEntered": "http://activitystrea.ms/schema/1.0/access",
    "Loggedin": "https://brindlewaye.com/xAPITerms/verbs/loggedin/",
    // "Searched": "http://activitystrea.ms/schema/1.0/search",
    "ChannelOpened": "http://activitystrea.ms/schema/1.0/open",
    "ExperienceClosed": "http://activitystrea.ms/schema/1.0/close",
    "Experienced": "http://activitystrea.ms/schema/1.0/experience",
    "Placed": "https://w3id.org/xapi/dod-isd/verbs/placed",
    "PortalEntered": "https://w3id.org/xapi/dod-isd/verbs/entered",
    "Weblinked": "http://activitystrea.ms/schema/1.0/use",
}

const EXT_DOMAIN = "https://aritize*`*nextechar*`*com"
const appExtns: { [key in AppState.ExtnList]: { path: string, restrictedTo: string[] | null } } = {
    code: { path: EXT_DOMAIN + "/code", restrictedTo: ["CodeEntered"] }, // CodeEntered
    // searchterm: { path: EXT_DOMAIN + "/searchterm", restrictedTo: ["Searched"] }, // Searched
    method: { path: EXT_DOMAIN + "/method", restrictedTo: ["ExperienceClosed"] }, // ExperienceClosed
    linktype: { path: EXT_DOMAIN + "/linktype", restrictedTo: ["Weblinked"] }, // Weblinked
    devicetype: { path: EXT_DOMAIN + "/devicetype", restrictedTo: null }, //every event has this
    appversion: { path: EXT_DOMAIN + "/appversion", restrictedTo: null }, //every event has this
    platform: { path: EXT_DOMAIN + "/platform", restrictedTo: null }, // every event has this
}


export const XapiKeys = {
    Verbs,
    Definitions,
    DeviceMapping,
    APP: {
        Verbs: appVerbs,
        Extensions: appExtns,
        EXPERIENCE_ID_PREFIX: "https://aritize.nextechar.com/experience/"
    }
}