import { Card } from "react-bootstrap"
import Skeleton from 'react-loading-skeleton';
import React from "react"
import { DonutLegend } from '../DonutLegend'

import { RadialChart, DiscreteColorLegend, Hint } from "react-vis"

import "./index.css";

interface Props {
    slices: Array<{ label: string, angle: number, color?: string }>,
    bigText: string,
    smallText?: string,
    loading?: boolean
}
export const DonutCard: React.FC<Props> = (props) => {

    const legendData = props.slices.map(item => {
        return { title: item.label, value: item.angle, color: item.color}
    })

    return <Card style={{ width: '30rem' }}>
        <Card.Body className="cardBody">
            {props.loading ? <Skeleton circle={true} width={120} height={120} /> :
            <div className="chart-side">
                <RadialChart className="radialChart"
                    padAngle={0.05}
                    colorType="literal"
                    showLabels={false}
                    labelsRadiusMultiplier={1.4}
                    animation={true}
                    data={props.slices}
                    width={220}
                    height={220}
                    innerRadius={50}
                    radius={70}
                    labelsStyle={{fontSize: '1rem'}}
                    >
                </RadialChart>
                </div>}
            {/* <DiscreteColorLegend items={legendData} orientation="horizontal" style={{fontSize: '1.1rem'}} /> */}
            <div className="info-side">
            <DonutLegend items={legendData} />
            <br/>
            <Card.Title style={{fontSize: '1.8rem'}}>{props.bigText}</Card.Title>
            <h6 className="small-text">{props.smallText}</h6>
            </div>

        </Card.Body>
    </Card>

}