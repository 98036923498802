import { combineReducers } from "redux";
import { AppState, ReduxAction } from "@declarations";
import { UIStateReducers } from "./UIStateReducers";
import { AppContentReducer } from "./AppContentReducer";
import { Reducer } from "react";

export const InitState: Readonly<AppState> = {
    dashboardData: {
        dwellAvg: 0,
        dwellTime: 0,
        estDwellTime: 0,
        totalOpens: { total: 0, breakdown: {} },
        totalViews: { total: 0, breakdown: {} },
        totalSaves: { total: 0, breakdown: {} },
        totalAddToCarts: { total: 0, breakdown: {} },
        periodChartData: { cartsData: [], opensData: [] },
    },
    embedInView: { id: "", brand: { name: "" }, name: "", title: "", pageUrl: "", cart: "none" },
    embedsListInView: [],
    experienceInView: { id: "", brand: "", name: "", channel: "" },
    experienceList: [],
    brandInView: { name: "", pageUrls: [], },
    brandsList: [],
    appBrandsList: [],
    appBrandInView: { name: "", channels: [] },
    channelInView: { experiences: [], name: "" },
    expIds: [],
    session: {
        state: "Verifying"
    },
    appDashboardData: {
        activity: [],
        overview: [{}]
    },
    uiStates: {
        waitingOverviewData: false,
        waitingPeriodicData: false,
        pageNotFound: false,
        pendingTasks: []
    }
}
export const reducer = (state: AppState = InitState, action: ReduxAction) => {
    const { uiStates, ...restOfState } = state;
    return {
        uiStates: UIStateReducers(uiStates, action),
        ...AppContentReducer(restOfState, action)

    }
}