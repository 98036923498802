import { takeLatest, all, takeLeading } from 'redux-saga/effects'
import { Actions } from '../Store/actions'
import {updatePeriodicData, updateOverviewData, updateAppActivity, updateAppOverview} from "./analyticsSagas"
import {getEmbedDetails,getEmbeds} from "./embedSagas"
import {getBrandDetails,getBrands} from "./brandsSagas"
import { updateSessionSaga, loginSaga } from './sessionSaga'
import { getExperiences } from './appExperiencesSaga'

export function* rootSaga() {
  yield all([
    /**
     * @see https://redux-saga.js.org/docs/basics/UsingSagaHelpers.html
     */
    // Run the startup saga when the application starts
    // takeLatest(StartupTypes.STARTUP, startup),
    // Call `fetchUser()` when a `FETCH_USER` action is triggered
    takeLeading(Actions.GET_BRANDS, getBrands),
    // takeLeading(Actions.GET_BRAND_DETAILS, getBrandDetails),
    takeLeading(Actions.GET_EMBEDS, getEmbeds),
    takeLeading(Actions.GET_EMBED_DETAILS, getEmbedDetails),
    takeLeading(Actions.GET_PERIODIC_DATA, updatePeriodicData),
    takeLeading(Actions.GET_OVERVIEW_DATA, updateOverviewData),

    takeLeading(Actions.GET_APP_ACTIVITY, updateAppActivity),
    takeLeading(Actions.GET_APP_OVERVIEW, updateAppOverview),
    takeLeading(Actions.GET_EXPERIENCE_LIST, getExperiences),

    takeLeading(Actions.UPDATE_SESSION, updateSessionSaga),
    takeLeading(Actions.LOGIN, loginSaga),


  ])
}
