import { PageProps, AppState, ReduxAction, ActionPayloads } from "@declarations";
import { DashboardView } from "..";
import React, { Dispatch } from "react";
import { makeAction, Actions } from "../../../Store/actions";
import { connect } from "react-redux";
import { Row, Col, Breadcrumb, InputGroup, FormControl, ListGroup, Navbar } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { ItemRow } from "../../../Widgets/ItemRow";

import "./index.css"

type RouteParams = { brand: string }
interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {

}
const BrandView: React.FC<Props> = (props) => {

    const [embedSearch, setEmbedSearch] = React.useState("")
    const [filteredEmbeds, setFilteredEmbeds] = React.useState<AppState.Embed[]>([]);
    let { brand } = useParams<RouteParams>();

    //can just check if props type is brand or embed to decide which to update
    function embedSearchChange(e: React.FormEvent<HTMLInputElement>) {
        const searchValue = e.currentTarget.value;
        setEmbedSearch(searchValue.toLowerCase())
    }

    React.useEffect(() => {
        setFilteredEmbeds(props.embedsListInView.filter(embed => embed.name.match(new RegExp(embedSearch, "i"))));
    }, [props.embedsListInView, embedSearch])

    React.useEffect(() => {
        if (!brand) return;
        props.getEmbeds(brand);
        // props.getOverviewData({}, brand);
        // props.getPeriodicData({}, brand);
    }, [brand]);

    function updateData(timeRange: ActionPayloads.TimeRange) {
        props.getEmbeds(brand);
        props.getOverviewData(timeRange, brand);
        props.getPeriodicData(timeRange, brand);
        props.getAppExperienceList();
    }
    return <Row>
        <Col xl={2}>

            <Navbar collapseOnSelect expand="xl" className="flex-column" >
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="navbar-toggle" />
                <Navbar.Collapse id="basic-navbar-nav">

                    <div className="sidebar">
                        <Breadcrumb style={{ backgroundColor: "transparent" }}>
                            <ol className="breadcrumb-list">
                                <li key="home"><Link to={{ pathname: `/web/` }} className="breadcrumb-item">All brands</Link></li>
                                <li key="brand"><Link to={{ pathname: `/web/brand/${brand}` }} className="breadcrumb-item">{brand}</Link></li>
                            </ol>
                        </Breadcrumb>


                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder="Search..."
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                value={embedSearch}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => { embedSearchChange(e) }}
                            />
                        </InputGroup>


                        <ListGroup variant="flush">
                            {
                                filteredEmbeds.length
                                    ? filteredEmbeds.map((b, i) => (<ListGroup.Item key={i}>
                                        <ItemRow name={b.title} pathname={`/web/brand/${brand}/embed/${encodeURIComponent(b.pageUrl)}`} />
                                    </ListGroup.Item>))
                                    : <ListGroup.Item>No embeds match that search.</ListGroup.Item>
                            }
                        </ListGroup>

                    </div>

                </Navbar.Collapse>
            </Navbar>

        </Col>
        <Col xl={10}>
            <DashboardView breadcrumbs={[brand]} onRequestUpdate={updateData} view={"brand"} count={props.embedsListInView.length} />
        </Col>
    </Row>
}

const mapStateToProps = (state: AppState) => {
    const { embedsListInView } = state;
    // console.log("props.embedsListInView",state)

    return {
        embedsListInView,
    };
}
const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => {

    return {
        getOverviewData: (timeRange: ActionPayloads.TimeRange = {}, id: string) => dispatch(
            makeAction(Actions.GET_OVERVIEW_DATA, { timeRange, scopeFilter: { scope: "brand", id } })
        ),
        getPeriodicData: (timeRange: ActionPayloads.TimeRange = {}, id: string) => dispatch(
            makeAction(Actions.GET_PERIODIC_DATA, { timeRange, scopeFilter: { scope: "brand", id } })
        ),

        getBrands: () => dispatch(makeAction(Actions.GET_BRANDS)),
        getEmbeds: (id: string) => dispatch(makeAction(Actions.GET_EMBEDS, { brand: id })),
        getAppExperienceList: () => dispatch(makeAction(Actions.GET_EXPERIENCE_LIST))
    }
}
export const BrandDashboardHOC = connect(mapStateToProps, mapDispatchToProps)(BrandView)
