// converts A regular JS date Object into veracity spec for dates
export function VeracityDate(date: Date) {
    return { $parseDate: { date: date.toISOString() } }
}

export function generateTimeSampling(from: Date, to: Date) {

    const timeDiff = (to.getTime() - from.getTime());
    const timeDiffDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    let timeGroupingPattern = "%Y-%m-%dT00:00:00.000Z";
    if (timeDiffDays < 3) {// if time range is less than 3 days get hourly data
        const timeDiffHours = Math.floor(timeDiff / (1000 * 60 * 60));
        if (timeDiffHours < 3) {// if time range is less than 3 hours get  data by minute
            const timeDiffMins = Math.floor(timeDiff / (1000 * 60));
            if (timeDiffMins < 3) {// if time range is less than 3 minutes get data by seconds
                return "%Y-%m-%dT%H:%M:%S.000Z"
            }
            return "%Y-%m-%dT%H:%M:00.000Z";
        }
        return "%Y-%m-%dT%H:00:00.000Z"

    } else return timeGroupingPattern
}