import { Row, Col, Card, Button } from "react-bootstrap"

import React, { Dispatch } from "react"

import { DiscreteColorLegend, XYPlot, FlexibleWidthXYPlot, AreaSeries, XAxis, YAxis, HorizontalGridLines, Highlight } from "react-vis"

import { DataItem, generateNewData } from "../../Utils/dummyData"
import { useLocation, useParams } from "react-router-dom"

import { durationSeconds } from '../../Utils/Durations/durations'
import { DonutCard } from "../../Widgets/DonutCard"
import { DatePicker, MomentRange } from "../../Widgets/DatePicker"
import { XapiKeys } from "../../Values/xapiKeys"
import { AppState, PageProps, ActionPayloads, } from "@declarations"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import "./index.css";
import { connect } from "react-redux"

import moment from "moment"


import { createSecureContext } from "tls"

type DeviceTypes = keyof typeof XapiKeys.DeviceMapping.toDefinitions;

/**
 * For zoom in activity graph
 * Get the array of x and y pairs.
 * The function tries to avoid too large changes of the chart.
 * @param {number} total Total number of values.
 * @returns {Array} Array of data.
 * @private
 */

const devicesToShow: DeviceTypes[] = ["Desktop", "Mobile"];

type RouteParams = { brand: string }
type Props = PageProps<typeof mapStateToProps/* , typeof mapDispatchToProps */> & {
    onRequestUpdate: (dateRange: ActionPayloads.TimeRange) => void,
    breadcrumbs: string[],
    view: "global" | "brand" | "embed",
    count?: number,
    context?: any
}

function NumberCard(props: { loading: boolean, line1: string, line2: string, className?: string }) {
    return <Card className="card">
        {props.loading ? <SkeletonTheme >
            <Skeleton height={125} />
        </SkeletonTheme>
            :
            <Card.Body className={props.className}>
                <div className="cardValue">
                    {
                        // if line1 has many values then it's duration
                        props.line1.split(" ").length > 1
                            ? props.line1.split(" ").map((item, index) => {
                                //if even index then it's a value
                                if (index % 2 === 0) {
                                    return <Card.Text className="card-number" key={index}>{item}</Card.Text>

                                } else {
                                    return <Card.Text className="card-units" key={index} style={{ whiteSpace: `pre` }}> {item}  </Card.Text>
                                }
                            })
                            : <Card.Text className="card-number">{props.line1} </Card.Text>}
                </div>
                <Card.Text className="cardTopic">{props.line2}</Card.Text>
            </Card.Body>}
    </Card>

}

const DashboardComponent: React.FC<Props> = (props) => {

    const [dateRange, setDateRange] = React.useState(5)
    const [dates, setDates] = React.useState<MomentRange>({
        startDate: moment().subtract(dateRange, 'days'),
        endDate: moment()
    })
    //for returning from activity graph zooms
    const [dateHold, setDateHold] = React.useState<MomentRange>({
        startDate: moment().subtract(dateRange, 'days'),
        endDate: moment()
    })

    //for activity graph
    const initialDrawLocation: any = null
    const [lastDrawLocation, setDrawLocation] = React.useState(initialDrawLocation)

    const [tMinus, setTMinus] = React.useState(0);
    let { brand } = useParams<RouteParams>();

    React.useEffect(() => {
        updateDates(dates)
    }, [])


    let { pathname } = useLocation();
    const pathSections = pathname.split('/');
    pathSections.shift();

    function updateDates({ startDate, endDate }: MomentRange) {
        setDates({ startDate, endDate });
        props.onRequestUpdate({
            from: startDate ? startDate.toDate() : undefined,
            to: endDate ? endDate.toDate() : undefined,

        })
    }
    function calendarUpdateDates({ startDate, endDate }: MomentRange) {
        setDateHold({ startDate, endDate });
        updateDates({ startDate, endDate })
    }
    //media queries for updating UI when screen width is less than 992px
    const mediaMatch = window.matchMedia('(max-width: 992px)');
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    React.useEffect(() => {
        const handler = (e: MediaQueryListEvent) => setMatches(e.matches);
        mediaMatch.addListener(handler);
        return () => mediaMatch.removeListener(handler);
    });

    function cartsPresent(): Boolean {
        if (props.view === "embed" && props.context && (props.context as AppState.Embed).cart !== "none") {
            return true;
        }

        if (props.totalAddToCarts && props.totalAddToCarts.total && props.totalAddToCarts.total > 0) {
            return true;
        }

        return false;
    }

    function applyRingTheme(data: Record<string, number>) {
        const colors: any = { Desktop: "#1340a0", Mobile: "#8fd0e7", AR: "#6c5af4" }
        return (
            Object.keys(data).map(keyName => ({ label: keyName, angle: data[keyName], color: colors[keyName] }))
        )
    }

    function calcOpenRate() {
        if (!props.totalViews.total) return 0.00;
        return (100 * props.totalOpens.total / props.totalViews.total).toFixed(2);
    }
    function calcCartRate() {
        if (!props.totalOpens.total) return 0.00;
        return (100 * props.totalAddToCarts.total / props.totalOpens.total).toFixed(2);
    }

    function calcOpensMax() {
        let max: number = 1
        for (let i = 0; i < props.periodChartData.opensData.length; i++) {
            const current = props.periodChartData.opensData[i]
            if (current.count && current.count > max) {
                max = current.count
            }
        }
        return max
    }

    function calcMaxY() {
        const max: number = calcOpensMax()
        if (lastDrawLocation && lastDrawLocation.top && lastDrawLocation.top <= max) {
            return lastDrawLocation.top;
        }
        return max;

    }

    function calcMinY() {
        if (lastDrawLocation && lastDrawLocation.bottom && lastDrawLocation.bottom > 0 && lastDrawLocation.bottom < calcMaxY()) {
            return lastDrawLocation.bottom;
        }
        return 0;
    }

    return <Row>

        <Col sm={12}>

            <Row className="header">

                <Col>

                    <div className="title-box">

                        {props.breadcrumbs.map((item, index) => (
                            props.view === "embed" && index === 1 //if second item in breadcrump array on embed view, it's the embed url
                                ? <>
                                    <a className="embed-link" href={props.context.pageUrl} target="_blank" rel="noopener noreferrer" key={index}>{props.context.title}</a>
                                </>
                                : <h1 className="title" key={index}>{item}</h1> //otherwise it's the company title
                        ))}

                        {props.view === "global" ? <h2 className="sub-title">{props.count + " Total"}</h2> : ""}
                        {props.view === "brand" ? <h2 className="sub-title">{props.count + " Embeds"}</h2> : ""}

                    </div>

                </Col>
                <Col >

                    <div className="datepicker-container">

                        <DatePicker
                            startDate={dates.startDate}
                            startDateId="START_DATE"
                            endDate={dates.endDate}
                            endDateId="END_DATE"
                            focusedInput={null}
                            onFocusChange={() => { }}
                            defaultRange={dateRange}
                            updateDate={calendarUpdateDates}
                        />

                    </div>


                </Col>

            </Row>

            <hr />

            <h2>Overview</h2>

            <br />

            <Row>

                <Col>
                    <NumberCard
                        loading={props.overviewBusy}
                        line1={props.totalViews.total.toLocaleString() || "NA"}
                        line2="Views"
                        className="blueCard"
                    />
                </Col>

                <Col>

                    <NumberCard
                        loading={props.overviewBusy}
                        line1={props.totalOpens.total.toLocaleString() || "NA"}
                        line2="Opens"
                        className="pastelCard"
                    />

                </Col>

                <Col>

                    <NumberCard
                        loading={props.overviewBusy}
                        line1={props.totalSaves.total.toLocaleString() || "NA"}
                        line2="Saves"
                        className="blueCard"
                    />

                </Col>

                {cartsPresent()
                    ? <Col>
                        <NumberCard
                            loading={props.overviewBusy}
                            line1={props.totalAddToCarts.total.toLocaleString() || "NA"}
                            line2="Adds to Cart"
                            className="purpleCard"
                        />
                    </Col>
                    : ""
                }

            </Row>

            <br />

            <Row>

                <Col>
                    <NumberCard
                        loading={props.overviewBusy}
                        line1={calcOpenRate() + "%"}
                        line2="Open Rate  (from views)"
                        className="pastelCard"
                    />
                </Col>

                {cartsPresent()
                    ? <Col>
                        <NumberCard
                            loading={props.overviewBusy}
                            line1={calcCartRate() + "%"}
                            line2="Add to Cart Rate  (from Opens)"
                            className="purpleCard"
                        />
                    </Col>
                    : ""}

                <Col>

                    <NumberCard
                        loading={props.overviewBusy}
                        line1={props.estDwellTime ? durationSeconds(props.estDwellTime) : "0"}
                        line2="Total Dwell Time (Estimated)"
                        className="blueCard"
                    />

                </Col>

                <Col>

                    <NumberCard
                        loading={props.overviewBusy}
                        line1={props.dwellAvg ? durationSeconds(props.dwellAvg) : "0"}
                        line2="Average Dwell Time"
                        className="pastelCard"
                    />

                </Col>

            </Row>
            <br />
            <hr />

            <h2>Activity</h2>
            <br />

            <p>Drag cursor over graph to zoom</p>
            <Row>

                <Col>

                    {props.areaChartBusy ? <Skeleton height={300} /> : <FlexibleWidthXYPlot
                        dontCheckIfEmpty={true}
                        xType="time"
                        animation
                        xDomain={
                            lastDrawLocation
                                ? [
                                    lastDrawLocation.left,
                                    lastDrawLocation.right
                                ]
                                : [
                                    dates.startDate.startOf("day"), dates.endDate.startOf("day")
                                ]
                        }
                        yDomain={
                            lastDrawLocation
                                ? [
                                    calcMinY(),
                                    calcMaxY()
                                ]
                                : [
                                    0, calcOpensMax()
                                ]

                        }
                        height={300}
                        getX={(d: AppState.PeriodChartSlice) => d.instance}
                        getY={(d: AppState.PeriodChartSlice) => d.count}>
                        <DiscreteColorLegend
                            style={{ position: 'absolute', left: '50px', top: '-50px', fontSize: '1rem' }}
                            orientation="horizontal"
                            items={[
                                {
                                    title: 'Opens',
                                    color: '#8fd0e7'
                                },
                            ]}
                        />
                        <AreaSeries data={props.periodChartData.opensData} animation={true} curve={'curveStep'} tickTotal={5} color={'#8fd0e7'} />
                        <XAxis
                            tickTotal={7}
                            tickLabelAngle={matches ? 45 : 0}
                            tickPadding={matches ? 35 : 0}
                            style={{ ticks: { fontSize: '0.8rem' } }} />
                        <YAxis style={{ ticks: { fontSize: '0.8rem' } }} />
                        <HorizontalGridLines />
                        <Highlight
                            onBrushEnd={(area: any) => {
                                if (calcMaxY() - calcMinY() < 1) return;
                                if (lastDrawLocation && moment(new Date(lastDrawLocation.right)).diff(moment(new Date(lastDrawLocation.left), "seconds")) < 1) return;
                                setDrawLocation(area)
                                if (area) {
                                    updateDates({
                                        startDate: moment(new Date(area.left)),
                                        endDate: moment(new Date(area.right))
                                    })
                                } else {
                                    updateDates({
                                        startDate: dateHold.startDate,
                                        endDate: dateHold.endDate
                                    })
                                }

                            }}
                            onDrag={(area: any) => {
                                setDrawLocation(
                                    {
                                        bottom: lastDrawLocation.bottom + (area.top - area.bottom),
                                        left: lastDrawLocation.left - (area.right - area.left),
                                        right: lastDrawLocation.right - (area.right - area.left),
                                        top: lastDrawLocation.top + (area.top - area.bottom)
                                    }
                                );
                            }}
                        />

                    </FlexibleWidthXYPlot>}

                    <Button
                        className="showcase-button"
                        variant="outline-primary"
                        onClick={() => {
                            updateDates({
                                startDate: dateHold.startDate,
                                endDate: dateHold.endDate
                            })
                            setDrawLocation(null)
                        }}
                    >
                        Reset Zoom
                    </Button>

                </Col>

            </Row>

            <br />
            <br />

            <hr />

            <h3>Device Breakdown</h3>
            <br />
            <Row>

                <Col className="deviceBreakdown">
                    <DonutCard
                        loading={props.overviewBusy}
                        bigText="Views"
                        smallText="by Device"
                        slices={applyRingTheme(props.totalViews.breakdown)} />

                </Col>
                <Col className="deviceBreakdown">
                    <DonutCard
                        loading={props.overviewBusy}
                        bigText="Opens"
                        smallText="by Device"
                        slices={applyRingTheme(props.totalOpens.breakdown)} />

                </Col>
                {cartsPresent()
                    ? <Col className="deviceBreakdown">
                        <DonutCard
                            loading={props.overviewBusy}
                            bigText="Adds to Cart"
                            smallText="by Device"
                            slices={applyRingTheme(props.totalAddToCarts.breakdown)} />
                    </Col>
                    : ""}

            </Row>


        </Col>
    </Row>

}

const mapStateToProps = (state: AppState) => {
    const { dwellAvg, dwellTime, totalViews, totalOpens, totalAddToCarts, totalSaves,
        periodChartData, estDwellTime
    } = state.dashboardData;
    const { brandsList, brandInView } = state;

    const { experienceList } = state;
    const { activity, overview } = state.appDashboardData;

    return {
        dwellAvg, dwellTime, totalViews, totalOpens, totalAddToCarts, totalSaves, periodChartData,
        brandsList, brandInView, estDwellTime, experienceList, activity, overview,
        areaChartBusy: state.uiStates.waitingPeriodicData,
        overviewBusy: state.uiStates.waitingOverviewData
    };
}

export const DashboardView = connect(mapStateToProps)(DashboardComponent)
