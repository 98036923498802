import { PageProps, AppState, ReduxAction, ActionPayloads } from "@declarations";
import { DashboardView } from "..";
import React, { useEffect, Dispatch } from "react";
import { makeAction, Actions } from "../../../Store/actions";
import { connect } from "react-redux";
import { Row, Col, Breadcrumb, Navbar } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import "./index.css"


type RouteParams = { brand: string, channel: string, experience: string }
interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {

}
const ExperienceView: React.FC<Props> = (props) => {

    let { brand, channel, experience } = useParams<RouteParams>();

    useEffect(() => {
        let brandInView = props.appBrandsList.filter(item => item.name === brand)[0]
        props.setAppBrandInView(brandInView)
    }, [props, brand])

    useEffect(() => {
        if (props.appBrandInView) {
            props.setChannelInView(props.appBrandInView.channels.filter(c => c.name === channel)[0])
        }
        if (props.channelInView) {
            props.setExperienceInView(props.channelInView.experiences.filter(e => e.id === experience)[0])
        }
    }, [props, channel, experience])

    useEffect(() => {
        if (!brand) return;
        props.getAppExperienceList()
    }, [brand]);

    function updateData(timeRange: ActionPayloads.TimeRange) {

        let id = experience; //exp[0].id;
        props.getAppOverviewData(timeRange, id, brand, channel);
        props.getAppActivityData(timeRange, id, brand, channel);


    }

    return <Row>
        <Col xl={2}>

            <Navbar collapseOnSelect expand="xl" className="flex-column" style={{ overflowWrap: `anywhere`, padding: `0rem` }} >
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="navbar-toggle" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <div>
                        <Breadcrumb style={{ backgroundColor: "transparent" }}>
                            <ol className="breadcrumb-list">
                                <li key="home"> <Link to={{ pathname: `/app/` }}>All brands</Link> </li>
                                <li key="brand"> <Link to={{ pathname: `/app/brand/${brand}` }}>{brand}</Link> </li>
                                <li key="channel"> <Link to={{ pathname: `/app/channel/${brand}/${channel}` }}>{channel}</Link> </li>
                                <li key="experience"> <Link to={{ pathname: `/app/experience/${brand}/${channel}/${experience}` }}>{props.experienceInView?.name}</Link> </li>
                            </ol>
                        </Breadcrumb>

                    </div>

                </Navbar.Collapse>
            </Navbar>
        </Col>
        <Col xl={10}>
            <DashboardView breadcrumbs={[brand, channel, props.experienceInView?.name]} onRequestUpdate={updateData} view={"experience"} context={props.experienceInView} />

        </Col>
    </Row>
}

const mapStateToProps = (state: AppState) => {
    const { appBrandsList, appBrandInView, channelInView, experienceInView } = state;

    return {
        appBrandsList, appBrandInView, channelInView, experienceInView
    };
}
const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => {
    return {
        setAppBrandInView: (brand: AppState.AppBrand) => dispatch(makeAction(Actions.SET_CURRENT_APP_BRAND, brand)),
        setChannelInView: (channel: AppState.Channel) => dispatch(makeAction(Actions.SET_CURRENT_CHANNEL, channel)),
        setExperienceInView: (exp: AppState.Experience) => dispatch(makeAction(Actions.SET_CURRENT_EXPERIENCE, exp)),
        getAppOverviewData: (timeR: ActionPayloads.TimeRange = {}, id: string, brand: string, channel: string) => dispatch(makeAction(Actions.GET_APP_OVERVIEW, { timeRange: timeR, scope: { experienceId: id, brand, channel } })),
        getAppActivityData: (timeR: ActionPayloads.TimeRange = {}, id: string, brand: string, channel: string) => dispatch(makeAction(Actions.GET_APP_ACTIVITY, { timeRange: timeR, verb: "Experienced", scope: { experienceId: id, brand, channel } })),
        getAppExperienceList: () => dispatch(makeAction(Actions.GET_EXPERIENCE_LIST))
    }
}
export const ExperienceDashboardHOC = connect(mapStateToProps, mapDispatchToProps)(ExperienceView)
