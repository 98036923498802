import React from 'react'

import { Row, Col, Form, Button } from 'react-bootstrap'

import './index.css'

interface Props {
}
export const PageNotFound: React.FC<Props> = (props) => {

    return <Row>
        <Col className="form-container">
        <h1 className="page-title">Page Not Found</h1>
        </Col>
    </Row>

}