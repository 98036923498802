
import { AppState, ReduxAction, Session } from "@declarations"
import { Actions, makeAction } from "../actions";

type AppContentState = Omit<AppState, "uiStates">;

export const AppContentReducer = (state: AppContentState, { type, payload }: ReduxAction): AppContentState => {
    console.log("reducing", type, payload)
    if (type === Actions.SET_SESSION) return { ...state, session: payload as Session }
    if (type === Actions.SET_BRANDS_LIST) return { ...state, brandsList: payload as AppState.Brand[] }
    if (type === Actions.SET_APP_BRANDS_LIST) return { ...state, appBrandsList: payload as AppState.AppBrand[]}
    if (type === Actions.SET_EMBEDS_LIST) return { ...state, embedsListInView: payload as AppState.Embed[] }
    if (type === Actions.SET_EXPERIENCE_LIST) return { ...state, experienceList: payload as AppState.Experience[] }
    if (type === Actions.SET_CURRENT_BRAND) return { ...state, brandInView: payload as AppState.Brand }
    if (type === Actions.SET_CURRENT_EMBED) return { ...state, embedInView: payload as AppState.Embed }
    if (type === Actions.SET_CURRENT_EXPERIENCE) return { ...state, experienceInView: payload as AppState.Experience }
    if (type === Actions.SET_CURRENT_APP_BRAND) return { ...state, appBrandInView: payload as AppState.AppBrand }
    if (type === Actions.SET_CURRENT_CHANNEL) return { ...state, channelInView: payload as AppState.Channel }
    if (type === Actions.SET_EXP_IDS) return { ...state, expIds: payload as string[] }
    if (type === Actions.SET_DASHBOARD_OVERVIEW_DATA) {
        const { periodChartData, ...dashboardData } = payload as AppState.DashboardData; // omit periodic data, typescript doesnot chceck for too many fields
        return {
            ...state, dashboardData: {
                ...state.dashboardData,
                ...dashboardData
            }
        }
    }
    if (type === Actions.SET_DASHBOARD_PERIODIC_DATA) {
        const periodChartData = payload as AppState.DashboardData["periodChartData"];
        return {
            ...state, dashboardData: {
                ...state.dashboardData, periodChartData
            }
        }
    }

    if (type === Actions.SET_APP_ACTIVITY) {
        const activity = payload as AppState.AppDashboardData["activity"];
        return {
            ...state, appDashboardData: {
                ...state.appDashboardData, activity
            }
        }
    }
    if (type === Actions.SET_APP_OVERVIEW) {
        const overview = payload as AppState.AppDashboardData["overview"];
        return {
            ...state, appDashboardData: {
                ...state.appDashboardData, overview
            }
        }
    }
    return state;
}