import React from 'react'

import { Row, Col, Form, Button, Alert } from 'react-bootstrap'

import './index.css'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { makeAction, Actions } from '../../Store/actions'
import { AppState, PageProps } from '@declarations'
import { Link } from 'react-router-dom'


export const SelectDashboard: React.FC = () => {
    const [login, setLogin] = React.useState("");
    const [password, setPassword] = React.useState("");
    return <Row>
        <Col className="form-container">
            <h1 className="page-title">NexTechAR Analytics </h1>
            <Row>
                <Col><Link to="/web" >
                    <div className="option">WebAR Analytics</div>
                </Link></Col>
                <Col><Link to="/app" >
                    <div className="option">AR Experiences Dashboard</div>
                </Link></Col>
            </Row>
        </Col>
    </Row>

}

function mapStateToProps(state: AppState) {
    return { loginError: (state.session as any).error }
}
function mapDispatchToProps(dispatch: Dispatch) {
    return {
        login: (email: string, password: string) => {
            dispatch(makeAction(Actions.LOGIN, { login: email, password }))
        }
    }
}