import { ReduxAction, AppState, ActionPayloads, Session } from "@declarations"
import { AppDataParams, AppActivityParams, WithScope } from "../Values/xapiKeys"
export enum Actions {
  GET_OVERVIEW_DATA = "GET_OVERVIEW_DATA",
  GET_PERIODIC_DATA = "GET_PERIODIC_DATA",
  GET_BRANDS = "GET_BRANDS",
  // GET_BRAND_DETAILS = "GET_BRAND_DETAILS",
  GET_EMBEDS = "GET_EMBEDS",
  GET_EMBED_DETAILS = "GET_EMBED_DETAILS",
  SET_OVERVIEW_WAIT_STATE = "SET_OVERVIEW_WAIT_STATE",
  SET_PERIODIC_DATA_WAIT_STATE = "SET_PERIODIC_DATA_WAIT_STATE",
  SET_BRANDS_LIST = "SET_BRANDS_LIST",
  SET_EMBEDS_LIST = "SET_EMBEDS_LIST",
  SET_DASHBOARD_OVERVIEW_DATA = "SET_DASHBOARD_OVERVIEW_DATA",
  SET_DASHBOARD_PERIODIC_DATA = "SET_DASHBOARD_PERIODIC_DATA",
  SET_CURRENT_EMBED = "SET_CURRENT_EMBED",
  SET_CURRENT_BRAND = "SET_CURRENT_BRAND",
  //App Dashboard
  GET_EXPERIENCE_LIST = "GET_EXPERIENCE_LIST",
  GET_EXP_BRAND_LIST = "GET_EXP_BRAND_LIST",
  GET_APP_OVERVIEW = "GET_APP_OVERVIEW",
  GET_APP_ACTIVITY = "GET_APP_ACTIVITY",
  SET_APP_OVERVIEW = "SET_APP_OVERVIEW",
  SET_APP_ACTIVITY = "SET_APP_ACTIVITY",
  SET_APP_BRANDS_LIST = "SET_APP_BRANDS_LIST",
  SET_CURRENT_EXPERIENCE = "SET_CURRENT_EXPERIENCE",
  SET_EXPERIENCE_LIST = "SET_EXPERIENCE_LIST",
  SET_CURRENT_APP_BRAND = "SET_CURRENT_APP_BRAND",
  SET_CURRENT_CHANNEL = "SET_CURRENT_CHANNEL",
  SET_EXP_IDS = "SET_EXP_IDS",
  //session
  LOGIN = "LOGIN",
  UPDATE_SESSION = "UPDATE_SESSION",
  SET_SESSION = "SET_SESSION",
  SET_NOT_FOUND = "SET_NOT_FOUND"
}


//overloads
export function makeAction(type: /* Actions.GET_BRAND_DETAILS | */ Actions.GET_EMBEDS, payload: { brand: string }): ReduxAction;
export function makeAction(type: Actions.GET_EMBED_DETAILS, payload: { embed: string }): ReduxAction;
// export function makeAction(type: Actions.GET_OVERVIEW_DATA, payload: ActionPayloads.ScopeFilter): ReduxAction;
export function makeAction(type: Actions.GET_PERIODIC_DATA | Actions.GET_OVERVIEW_DATA, payload: { timeRange: ActionPayloads.TimeRange, scopeFilter: ActionPayloads.ScopeFilter }): ReduxAction;
export function makeAction(type: Actions.GET_BRANDS): ReduxAction;
export function makeAction(type: Actions.SET_DASHBOARD_OVERVIEW_DATA, payload: Omit<AppState.DashboardData, "periodChartData">): ReduxAction;
export function makeAction(type: Actions.SET_DASHBOARD_PERIODIC_DATA, payload: AppState.DashboardData["periodChartData"]): ReduxAction;
export function makeAction(type: Actions.SET_EMBEDS_LIST, payload: AppState.Embed[]): ReduxAction;
export function makeAction(type: Actions.SET_BRANDS_LIST, payload: AppState.Brand[]): ReduxAction;
export function makeAction(type: Actions.SET_CURRENT_EMBED, payload: AppState.Embed): ReduxAction;
export function makeAction(type: Actions.SET_SESSION, payload: Session): ReduxAction;
export function makeAction(type: Actions.LOGIN, payload: { login: string, password: string }): ReduxAction;
export function makeAction(type: Actions.GET_APP_OVERVIEW, payload: WithScope<AppDataParams>): ReduxAction;
export function makeAction(type: Actions.GET_APP_ACTIVITY, payload: WithScope<AppActivityParams>): ReduxAction;
export function makeAction(type: Actions.SET_APP_OVERVIEW, payload: AppState.AppDashboardData["overview"]): ReduxAction;
export function makeAction(type: Actions.SET_APP_ACTIVITY, payload: AppState.AppDashboardData["activity"]): ReduxAction;
export function makeAction(type: Actions.SET_APP_BRANDS_LIST, payload: AppState.AppBrand[]): ReduxAction;
export function makeAction(type: Actions.SET_CURRENT_APP_BRAND, payload: AppState.AppBrand): ReduxAction;
export function makeAction(type: Actions.SET_CURRENT_CHANNEL, payload: AppState.Channel): ReduxAction;
export function makeAction(type: Actions.SET_CURRENT_EXPERIENCE, payload: AppState.Experience): ReduxAction;
export function makeAction(type: Actions.SET_EXP_IDS, payload: string[]): ReduxAction;
export function makeAction(type: Actions.SET_NOT_FOUND, payload: boolean): ReduxAction;
export function makeAction(type: Actions.UPDATE_SESSION | Actions.GET_EXPERIENCE_LIST | Actions.SET_NOT_FOUND): ReduxAction;
export function makeAction(
  type: Actions.SET_OVERVIEW_WAIT_STATE | Actions.SET_PERIODIC_DATA_WAIT_STATE,
  payload: boolean
): ReduxAction;
// definition
export function makeAction(type: Actions, payload?: Record<string, any> | boolean): ReduxAction {
  return { type, payload }
}
