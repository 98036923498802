import React from "react"
import { Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"


interface Props {
    name: string
    pathname: string
}

export const ItemRow: React.FC<Props> = (props) => {
    return <Row>
        <Col style={{ textAlign: "start" }}>
            <Link to={props.pathname}>{props.name}</Link>
        </Col>
    </Row>
}