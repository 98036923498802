import { Row, Col, Card, Button } from "react-bootstrap"

import React, { Dispatch } from "react"

import { DiscreteColorLegend, XYPlot, FlexibleWidthXYPlot, AreaSeries, XAxis, YAxis, HorizontalGridLines, Highlight } from "react-vis"

import { useLocation, useParams } from "react-router-dom"

import { durationSeconds } from '../../Utils/Durations/durations'
import { NumberCard } from "../../Widgets/NumberCard"
import { DonutCard } from "../../Widgets/DonutCard"
import { DatePicker, MomentRange } from "../../Widgets/DatePicker"
import { XapiKeys } from "../../Values/xapiKeys"
import { AppState, PageProps, ActionPayloads, } from "@declarations"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import "./index.scss";
import { connect } from "react-redux"

import moment from "moment"


import { createSecureContext } from "tls"
import { PageNotFound } from "../PageNotFound"

type DeviceTypes = keyof typeof XapiKeys.DeviceMapping.toDefinitions;

/**
 * For zoom in activity graph
 * Get the array of x and y pairs.
 * The function tries to avoid too large changes of the chart.
 * @param {number} total Total number of values.
 * @returns {Array} Array of data.
 * @private
 */

const devicesToShow: DeviceTypes[] = ["Desktop", "Mobile"];

type RouteParams = { brand: string }
type Props = PageProps<typeof mapStateToProps/* , typeof mapDispatchToProps */> & {
    onRequestUpdate: (dateRange: ActionPayloads.TimeRange) => void,
    breadcrumbs: string[],
    view: "global" | "brand" | "channel" | "experience",
    count?: number,
    context?: any
}


const DashboardComponent: React.FC<Props> = (props) => {

    const [dateRange, setDateRange] = React.useState(5)
    const [dates, setDates] = React.useState<MomentRange>({
        startDate: moment().subtract(dateRange, 'days'),
        endDate: moment()
    })
    //for returning from activity graph zooms
    const [dateHold, setDateHold] = React.useState<MomentRange>({
        startDate: moment().subtract(dateRange, 'days'),
        endDate: moment()
    })

    //for activity graph
    const initialDrawLocation: any = null
    const [lastDrawLocation, setDrawLocation] = React.useState(initialDrawLocation)

    const [tMinus, setTMinus] = React.useState(0);
    let { brand } = useParams<RouteParams>();

    React.useEffect(() => {
        updateDates(dates);
        console.log("updating")
    }, [])


    let { pathname } = useLocation();
    const pathSections = pathname.split('/');
    pathSections.shift();

    function updateDates({ startDate, endDate }: MomentRange) {
        setDates({ startDate, endDate });
        props.onRequestUpdate({
            from: startDate ? startDate.toDate() : undefined,
            to: endDate ? endDate.toDate() : undefined,

        })
    }
    function calendarUpdateDates({ startDate, endDate }: MomentRange) {
        setDateHold({ startDate, endDate });
        updateDates({ startDate, endDate })
    }
    //media queries for updating UI when screen width is less than 992px
    const mediaMatch = window.matchMedia('(max-width: 992px)');
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    React.useEffect(() => {
        const handler = (e: MediaQueryListEvent) => setMatches(e.matches);
        mediaMatch.addListener(handler);
        return () => mediaMatch.removeListener(handler);
    });

    function calcExpDwellAvg() {
        return (
            props.ExperienceClosed && props.ExperienceClosed.total && props.ExperienceClosed.total.length
                ? (props.ExperienceClosed.total[0].secondsFromStart / props.ExperienceClosed.total[0].count)
                : 0
        );
    }

    function calcPortalDwellAvg() {
        return (
            props.PortalEntered && props.PortalEntered.total && props.PortalEntered.total.length
                ? (props.PortalEntered.total[0].secondsFromStart / props.PortalEntered.total[0].count)
                : 0
        );
    }

    function calcPlacedDwellAvg() {
        return (
            props.Placed && props.Placed.total && props.Placed.total.length
                ? (props.Placed.total[0].secondsFromStart / props.Placed.total[0].count)
                : 0
        );
    }

    //platform breakdown
    function donutPrepPlatformData(data: Array<{ _id: string, count: number }>) {

        const iOS: any = { label: "iOS", angle: 0, color: "#6c5af4" }

        const Android: any = { label: "Android", angle: 0, color: "#8fd0e7" }

        for (let i = 0; i < data.length; i++) {
            if (data[i]._id === "ios") iOS.angle += data[i].count
            if (data[i]._id === null) iOS.angle += data[i].count
            if (data[i]._id === "android") Android.angle += data[i].count
        }

        let result = []
        if (iOS.angle > 0) result.push(iOS)
        if (Android.angle > 0) result.push(Android)
        return result;
    }

    // function prepActivityData(data: AppState.PeriodChartSlice[]) {
    //     return data.map(item => ({ instance: item.isoTime, count: item.openCount }))
    // }

    //close method breakdown
    function donutPrepCloseMethodData(data: Array<{ _id: string, count: number }>) {

        const colors: any = { "UI Button": "#8fd0e7", "App Closed": "#1340a0", }

        return data.map(item => {
            if (item._id === "uibutton") { item._id = "UI Button" } else { item._id = "App Closed" }
            return { label: item._id, angle: item.count, color: colors[item._id] }
        })
    }

    function barPrepDeviceTypeData(data: Array<{ _id: string, count: number }>) {

        return data.map(item => {
            if (item._id === null || item._id === "unknown") item._id = "iOS";
            return { x: item._id, y: item.count }
        })
    }


    function applyRingTheme(data: Record<string, number>) {
        const colors: any = { Desktop: "#1340a0", Mobile: "#8fd0e7", AR: "#6c5af4" }
        return (
            Object.keys(data).map(keyName => ({ label: keyName, angle: data[keyName], color: colors[keyName] }))
        )
    }


    function calcActivityMax() {
        let max: number = 1
        for (let i = 0; i < props.activity.length; i++) {
            const current = props.activity[i]
            if (current.count && current.count > max) {
                max = current.count
            }
        }
        return max
    }

    function calcMaxY() {
        const max: number = calcActivityMax()
        if (lastDrawLocation && lastDrawLocation.top && lastDrawLocation.top <= max) {
            return lastDrawLocation.top;
        }
        return max;

    }

    function calcMinY() {
        if (lastDrawLocation && lastDrawLocation.bottom && lastDrawLocation.bottom > 0 && lastDrawLocation.bottom < calcMaxY()) {
            return lastDrawLocation.bottom;
        }
        return 0;
    }
    if (props.pageNotFound) return <PageNotFound />

    return <Row>

        <Col sm={12}>

            <Row className="header">

                <Col>

                    <div className="title-box">

                        {props.breadcrumbs.map((item, index) => {
                            if ((props.view === "channel" || props.view === "experience") && index === 1) return <h2 className="channel-title" key={index}>Channel: {item}</h2>
                            if (props.view === "experience" && index === 2) return <h2 className="exp-title" key={index}>Experience: {item}</h2>
                            return <h1 className="title" key={index}>{item}</h1>
                        })}

                        {props.view === "global" ? <h2 className="sub-title">{props.count + " Total"}</h2> : ""}
                        {props.view === "brand"
                            ?
                            props.count && props.count > 1 ? <h2 className="sub-title">{props.count + " Channels"}</h2>
                                : <h2 className="sub-title">{props.count + " Channel"}</h2>

                            : ""}
                        {props.view === "channel"
                            ?
                            props.count && props.count > 1 ? <h2 className="sub-title">{props.count + " Experiences"}</h2>
                                : <h2 className="sub-title">{props.count + " Experience"}</h2>
                            : ""}

                    </div>

                </Col>
                <Col >

                    <div className="datepicker-container">

                        <DatePicker
                            startDate={dates.startDate}
                            startDateId="START_DATE"
                            endDate={dates.endDate}
                            endDateId="END_DATE"
                            focusedInput={null}
                            onFocusChange={() => { }}
                            defaultRange={dateRange}
                            updateDate={calendarUpdateDates}
                        />

                    </div>


                </Col>

                <hr />

            </Row>



            <h2>Overview</h2>

            <br />
            <Row>
                {
                    props.Loggedin && props.Loggedin.total && props.Loggedin.total.length
                        ?
                        <Col>
                            <NumberCard
                                loading={false}
                                bigText={props.Loggedin.total[0].count.toLocaleString()}
                                smallText="Log-ins"
                                className="pastelCard" />
                        </Col>
                        : ""
                }
                <Col>
                    <NumberCard
                        loading={false}
                        bigText={props.Experienced && props.Experienced.total && props.Experienced.total.length
                            ? props.Experienced.total[0].count.toLocaleString() : "0"}
                        smallText="Experience Opens"
                        className="blueCard" />
                </Col>
                {props.PortalEntered && props.PortalEntered.total && props.PortalEntered.total.length
                    ?
                    <Col>
                        <NumberCard
                            loading={false}
                            bigText={props.PortalEntered.total[0].count.toLocaleString()}
                            smallText="Portal Entries"
                            className="pastelCard" />
                    </Col>
                    : ""
                }
                <Col>
                    <NumberCard
                        loading={false}
                        bigText={props.Placed && props.Placed.total && props.Placed.total.length
                            ? props.Placed.total[0].count.toLocaleString() : "0"}
                        smallText="Models Placed"
                        className="purpleCard" />
                </Col>

                {/* 
                <Col>
                    <NumberCard
                        loading={false}
                        bigText={props.ChannelOpened && props.ChannelOpened.total && props.ChannelOpened.total.length
                            ? props.ChannelOpened.total[0].count.toLocaleString() : "0"}
                        smallText="Channels Opened"
                        className="pastelCard" />
                </Col>
                <Col>
                    <NumberCard
                        loading={false}
                        bigText={props.CodeEntered && props.CodeEntered.total && props.CodeEntered.total.length
                            ? props.CodeEntered.total[0].count.toLocaleString() : "0"}
                        smallText="Code Entries"
                        className="purpleCard" />
                </Col>

                {
                    props.Weblinked && props.Weblinked.total && props.Weblinked.total.length
                        ? <Col>
                            <NumberCard
                                loading={false}
                                bigText={props.Weblinked.total[0].count.toLocaleString()}
                                smallText="Weblink Entries"
                                className="pastelCard" />
                        </Col>
                        : ""} */}
            </Row>
            <Row>

                <Col>
                    <NumberCard
                        loading={false}
                        bigText={props.ExperienceClosed && props.ExperienceClosed.total && props.ExperienceClosed.total.length
                            ? durationSeconds(props.ExperienceClosed.total[0].secondsFromStart) : "0"}
                        smallText="Total Experience Dwell Time"
                        className="blueCard" />
                </Col>
                <Col>
                    <NumberCard
                        loading={false}
                        bigText={durationSeconds(calcExpDwellAvg()) || "0"}
                        smallText="Avg Experience Dwell Time"
                        className="blueCard" />
                </Col>
            </Row>
            {/* <Row>

                <Col>
                    <NumberCard
                        loading={false}
                        bigText={props.PortalEntered && props.PortalEntered.total && props.PortalEntered.total.length
                            ? durationSeconds(props.PortalEntered.total[0].secondsFromStart) : "0"}
                        smallText="Total Portal Dwell Time"
                        className="pastelCard" />
                </Col>
                <Col>
                    <NumberCard
                        loading={false}
                        bigText={durationSeconds(calcPortalDwellAvg()) || "0"}
                        smallText="Avg Portal Dwell Time"
                        className="pastelCard" />
                </Col>
            </Row> */}
            {/* <Row>



                <Col>
                    <NumberCard
                        loading={false}
                        bigText={props.Placed && props.Placed.total && props.Placed.total.length
                            ? durationSeconds(props.Placed.total[0].secondsFromStart) : "0"}
                        smallText="Total Time Placing Models"
                        className="purpleCard" />
                </Col>
                <Col>
                    <NumberCard
                        loading={false}
                        bigText={durationSeconds(calcPlacedDwellAvg()) || "0"}
                        smallText="Avg Time Placing Models"
                        className="purpleCard" />
                </Col>
            </Row> */}


            <br />
            <hr />

            <h2>Activity</h2>
            <br />

            <p>Drag cursor over graph to zoom</p>
            <Row>


                <Col>

                    {props.areaChartBusy ? <Skeleton height={300} /> : <FlexibleWidthXYPlot
                        dontCheckIfEmpty={true}
                        xType="time"
                        animation
                        xDomain={
                            lastDrawLocation
                                ? [
                                    lastDrawLocation.left,
                                    lastDrawLocation.right
                                ]
                                : [
                                    dates.startDate.startOf("day"), dates.endDate.startOf("day")
                                ]
                        }
                        yDomain={
                            lastDrawLocation
                                ? [
                                    calcMinY(),
                                    calcMaxY()
                                ]
                                : [
                                    0, calcActivityMax()
                                ]

                        }
                        height={300}
                        getX={(d: AppState.PeriodChartSlice) => d.instance}
                        getY={(d: AppState.PeriodChartSlice) => d.count}>
                        <DiscreteColorLegend
                            style={{ position: 'absolute', left: '50px', top: '-50px', fontSize: '1rem' }}
                            orientation="horizontal"
                            items={[
                                {
                                    title: 'Experience Opens',
                                    color: '#1340a0'
                                },
                            ]}
                        />
                        {/* <AreaSeries data={props.periodChartData.opensData} animation={true} curve={'curveStep'} tickTotal={5} color={'#1340a0;'} /> */}
                        <AreaSeries data={props.activity} animation={true} curve={'curveStep'} tickTotal={5} color={'#1340a0'} />
                        <XAxis
                            tickTotal={7}
                            tickLabelAngle={matches ? 45 : 0}
                            tickPadding={matches ? 35 : 0}
                            style={{ ticks: { fontSize: '0.8rem' } }} />
                        <YAxis style={{ ticks: { fontSize: '0.8rem' } }} />
                        <HorizontalGridLines />
                        <Highlight
                            onBrushEnd={(area: any) => {
                                if (calcMaxY() - calcMinY() < 1) return;
                                if (lastDrawLocation && moment(new Date(lastDrawLocation.right)).diff(moment(new Date(lastDrawLocation.left), "seconds")) < 1) return;
                                setDrawLocation(area)
                                if (area) {
                                    updateDates({
                                        startDate: moment(new Date(area.left)),
                                        endDate: moment(new Date(area.right))
                                    })
                                } else {
                                    updateDates({
                                        startDate: dateHold.startDate,
                                        endDate: dateHold.endDate
                                    })
                                }

                            }}
                            onDrag={(area: any) => {
                                setDrawLocation(
                                    {
                                        bottom: lastDrawLocation.bottom + (area.top - area.bottom),
                                        left: lastDrawLocation.left - (area.right - area.left),
                                        right: lastDrawLocation.right - (area.right - area.left),
                                        top: lastDrawLocation.top + (area.top - area.bottom)
                                    }
                                );
                            }}
                        />

                    </FlexibleWidthXYPlot>}

                    <Button
                        className="showcase-button"
                        variant="outline-primary"
                        onClick={() => {
                            updateDates({
                                startDate: dateHold.startDate,
                                endDate: dateHold.endDate
                            })
                            setDrawLocation(null)
                        }}
                    >
                        Reset Zoom
                    </Button>

                </Col>

            </Row>

            <br />
            <br />

            <hr />

            <h3>Device Breakdown</h3>
            <br />


            <Row className="breakdowns">

                {
                    props.Loggedin && props.Loggedin.platform && props.Loggedin.platform.length
                        ?
                        <Col>
                            <DonutCard
                                loading={false}
                                bigText="Log Ins"
                                smallText="by Platform"
                                slices={donutPrepPlatformData(props.Loggedin.platform)} />
                        </Col>
                        : ""
                }

                {/* {
                    props.ChannelOpened && props.ChannelOpened.platform
                        ?
                        <Col>
                            <DonutCard
                                loading={false}
                                bigText="Channels Opened"
                                smallText="by Platform"
                                slices={donutPrepPlatformData(props.ChannelOpened.platform)} />
                        </Col>
                        : ""
                } */}

                {
                    props.Experienced && props.Experienced.platform
                        ?
                        <Col>
                            <DonutCard
                                loading={false}
                                bigText="Experience Opens"
                                smallText="by Platform"
                                slices={donutPrepPlatformData(props.Experienced.platform)} />
                        </Col>
                        : ""
                }


                {/* {
                    props.ExperienceClosed && props.ExperienceClosed.method
                        ?
                        <Col>
                            <DonutCard
                                loading={false}
                                bigText="Experience Closures"
                                smallText="by Method"
                                slices={donutPrepCloseMethodData(props.ExperienceClosed.method)} />
                        </Col>
                        : ""
                } */}

                {/* {
                    props.PortalEntered && props.PortalEntered.platform
                        ? <Col>
                            <DonutCard
                                loading={false}
                                bigText="Portal Entries"
                                smallText="by Platform"
                                slices={donutPrepPlatformData(props.PortalEntered.platform)} />
                        </Col>
                        : ""
                } */}

                {/* {
                    props.Placed && props.Placed.platform
                        ? <Col>
                            <DonutCard
                                loading={false}
                                bigText="Models Placed"
                                smallText="by Platform"
                                slices={donutPrepPlatformData(props.Placed.platform)} />
                        </Col>
                        : ""
                } */}

            </Row>

        </Col>
    </Row>

}

const mapStateToProps = (state: AppState) => {

    const { experienceList, uiStates: { pageNotFound } } = state;
    const { activity, overview } = state.appDashboardData;
    const { CodeEntered, Loggedin, ChannelOpened, ExperienceClosed, Experienced, Placed, PortalEntered, Weblinked } = overview[0]

    return {
        experienceList, activity,
        CodeEntered, Loggedin, ChannelOpened, ExperienceClosed, Experienced, Placed, PortalEntered, Weblinked,
        areaChartBusy: state.uiStates.waitingPeriodicData, overview,
        overviewBusy: state.uiStates.waitingOverviewData,
        pageNotFound
    };
}

export const DashboardView = connect(mapStateToProps)(DashboardComponent)
