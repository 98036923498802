import React from 'react'

import { Row, Col, Form, Button, Alert } from 'react-bootstrap'

import './index.css'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { makeAction, Actions } from '../../Store/actions'
import { AppState, PageProps } from '@declarations'

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {
}

const LoginComponent: React.FC<Props> = (props) => {
    const [login, setLogin] = React.useState("");
    const [password, setPassword] = React.useState("");
    return <Row>
        <Col className="form-container">
            <h1 className="page-title">NexTechAR Analytics Login</h1>
            <Form className="login-form">
                <Form.Group controlId="formBasicEmail">
                    <Form.Control type="email" placeholder="Email" value={login} onChange={(e: any) => setLogin(e.target.value)} />
                </Form.Group>

                <Form.Group controlId="formBasicPassword" >
                    <Form.Control type="password" placeholder="Password" value={password} onChange={(e: any) => setPassword(e.target.value)} />
                </Form.Group>
                {/* <Form.Group controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Remember Me" />
                </Form.Group> */}

                <Button variant="primary" type="button" onClick={() => props.login(login, password)}>
                    Submit
            </Button>
                {props.loginError && <Alert>{props.loginError}</Alert>}
            </Form>
        </Col>
    </Row>

}

function mapStateToProps(state: AppState) {
    return { loginError: (state.session as any).error }
}
function mapDispatchToProps(dispatch: Dispatch) {
    return {
        login: (email: string, password: string) => {
            dispatch(makeAction(Actions.LOGIN, { login: email, password }))
        }
    }
}
export const Login = connect(mapStateToProps, mapDispatchToProps)(LoginComponent)